import React, { useEffect, useState } from 'react'
import Slider from './Slider'
import ContinueWatching from './ContinueWatching'
import PopularMovies from './PopularMovies'
import Genres from './Genres'
import RecommendedMovies from './RecommendedMovies'
import MovieBanner from './MovieBanner'
import { bindActionCreators } from 'redux'
import { useDispatch } from 'react-redux'
import * as HomeAction from '../../../actions/HomeActions'
import * as MovieAction from '../../../actions/MovieAction'
import UpcomingMovies from './UpcomingMovies'
import Category from './Category'
import WebSeries from './WebSeries'
import Language from './Language'
import RemainingCategory from './RemainingCategory'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'
import logo_gif from "../../../assets/regal_images/gif_load.gif"
import { Box, Checkbox, CircularProgress, FormControlLabel, Modal, Typography } from '@mui/material'
import Artist from './Artist'
import LeavingSoon from './LeavingSoon'
import { Base64 } from 'js-base64'
import Highlights from './Highlights'



const styleforAlert = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  maxHeight: "90vh",
  overflow: "scroll",
  overflowX: "hidden",
  width: "90%",
  bgcolor: 'rgb(25,25,25)',
  border: '2px solid rgb(255,255,255,0.5)',
  boxShadow: 24,
  borderRadius: "10px",
  p: 2,
}

export default function Home() {
  const dispatch = useDispatch()
  const history = useHistory()
  const { getAllHomeData } = bindActionCreators(HomeAction, dispatch)
  const { getAllSubacategory } = bindActionCreators(MovieAction, dispatch)
  const profile_id = JSON.parse(sessionStorage.getItem('profile'))?.id
  const user_id = JSON.parse(sessionStorage.getItem('user'))?.id
  const login_details = localStorage.getItem("user")
  const isLoggedIn = sessionStorage.getItem('isLoggedIn')
  const [home, setHome] = useState()
  const [loaderCompletion, setLoaderCompletion] = useState(true)
  const [counter, setCounter] = useState(0);
  const [subCat, setSubCat] = useState([])
  const [subCat1, setSubCat1] = useState([])
  const [remainingCat, setRemainingCat] = useState([])
  const getSignal = sessionStorage.getItem("AgreeWithDisclaimer")

  useEffect(() => {
    (async () => {
      // if (!getSignal) {
      //   if (!loaderCompletion && !login_details) {

      //     setTimeout(() => {
      //       sessionStorage.setItem('logoDisplayedBefore', 'true');
      //     }, 5000)
      //   }
      // }
      const resData = await getAllSubacategory()
      setSubCat(resData?.data)
    })();
  }, [])


  useEffect(() => {
    // setTimeout(() => { setLoaderCompletion(true) }, 6000)
    if (login_details && !isLoggedIn) {
      setLoaderCompletion(true)
      sessionStorage.setItem("user", login_details)
      sessionStorage.setItem("isLoggedIn", true)
      // setTimeout(() => {
      history.push("/")
      // }, 1000)

      // dispatch(HomeAction.login(JSON.parse(login_details), history))
    }
    (async () => {
      let userObj = {
        user_id: user_id ? user_id : "null",
        profile_id: profile_id ? profile_id : "null"
      }
      const resData = await getAllHomeData(userObj)
      // setHome(resData?.data)
      setHome({ ...resData, data: JSON.parse(Base64.decode(resData.data)) })
      document.body.scrollTop = document.documentElement.scrollTop = 0;
    })()
  }, [isLoggedIn])

  useEffect(() => {
    const updateCounter = () => {
      setCounter((prevCounter) => prevCounter + 1);
    };
    const timerId = setTimeout(updateCounter, 2000);
    return () => {
      clearTimeout(timerId);
    };
  }, [counter]);

  // useEffect(() => {
  //   const logoDisplayedBefore = sessionStorage.getItem('logoDisplayedBefore');
  //   if (logoDisplayedBefore) {
  //     setLoaderCompletion(true);
  //   } else {
  //     sessionStorage.setItem('logoDisplayedBefore', 'true');
  //   }
  // }, []);




  return (
    <>

      <div style={{ opacity: loaderCompletion ? "1" : "0" }}>
        <Slider />

        <ContinueWatching />

        {
          subCat ? subCat?.series_data?.map((data, index) => (
            <div>

              {
                index == 2 &&
                <>
                  <Category category={subCat?.movie_data} />
                  <MovieBanner/>
                </>

              }
              <WebSeries category={data} />
            </div>
          ))
            :
            <h4 style={{ textAlign: "center", marginTop: "8rem" }}></h4 >
        }




      </div >
      <div style={{ display: !loaderCompletion ? "" : "none" }}>
        <div style={{ position: "absolute", top: "0", left: "0", zIndex: "999", margin: "auto", background: "#010101", width: "100%" }}>
          <div style={{ display: "flex", justifyContent: "center", height: "100vh" }}>
            <img src={logo_gif} alt='load-gif' style={{ height: "100vh", display: "flex", alignSelf: "center" }} />
          </div>
        </div>
      </div>
    </>
  )
}
