import React, { useState } from 'react'
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import { Typography } from '@mui/material';
import logo from '../assets/regal_images/regal_logo.png'
import { IMAGE_ENDPOINT } from '../constant/ApiList';
import expired from '../assets/regal_images/expired1.png'

const Qr_detail = ({ redeemdetail }) => {

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    maxHeight: "100vh",
    width: 400,
    bgcolor: 'rgb(0,0,0,0.9)',
    boxShadow: "rgba(238, 238, 238, 0.25) 0px 0px 4px 1px, rgba(206, 206, 206, 0.3) 0px 0px 11px 2px",
    borderRadius: "10px",
    p: 4,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center"
  };

  var today = new Date();
  var dd = String(today.getDate()).padStart(2, '0');
  var mm = String(today.getMonth() + 1).padStart(2, '0');
  var yyyy = today.getFullYear();

  today = yyyy + '-' + mm + '-' + dd;

  const [open, setOpen] = useState(false)
  const [qrImg, setQrimg] = useState(null)
  const [expireDate, setExpireDate] = useState(false)


  const handleqr = (path, ex_date) => {
    setOpen(true)
    setQrimg(path)
    setExpireDate(ex_date)
  }
  const handleClose = () => {
    setOpen(false)
  }


  return (
    <div>
      <div className='qr-detail-div'>
        <div className='qr-table'>
          <div className='header-row'>
            <div>QR Name</div>
            <div>Offer</div>
            <div>Expire Date</div>
            <div>QR Code</div>
          </div>
          <div className='odd-even'>
            {
              redeemdetail?.length ?
              redeemdetail?.map((data, index) => (
                <div className='grid-1' key={index}>
                  <div>{data?.qr_name}</div>
                  <div>{data?.qr_offer}</div>
                  <div>{data?.expire_date}</div>
                  <div><button onClick={() => handleqr(data?.image_path, data?.expire_date)} style={{ padding: "5px 15px", cursor: "pointer", borderRadius: "6px", background: "transparent", color: "#e4e4e4" }}>View</button></div>
                  {/* <td><img height={20} width={50} src={`${IMAGE_ENDPOINT}/${data?.image_path}`} alt="" /></td> */}
                </div>
              )):
              <div className='mt-4' style={{textAlign:"center"}}>No Records Found.</div>
            }
          </div>
        </div>
      </div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div className='model-close-icon' style={{ display: "flex", alignSelf: "flex-end" }}>
            <i onClick={handleClose} style={{ color: "black", zIndex: "9", cursor: "pointer", padding: "4px 6px", backgroundColor: "white", fontSize: "1.1rem", borderRadius: "11px" }} className="fa fa-times" aria-hidden="true"></i>
          </div>


          <div style={{ paddingBottom: "19px", marginTop: "-1.8rem", borderBottom: "1px solid white", width: "100%", display: "flex", justifyContent: "center" }}>
            <img src={logo} alt='regal_logo' style={{ width: "100px" }} />
          </div>
          { expireDate < today  ? <div  >
            {/* <img style={{ position: "relative", top: "70px", zIndex: "11", height: "31px", width: "150px" }} src={expired} alt="" /> */}
            <img style={{ position: "relative", top: "70px", left: "10px", zIndex: "11", height: "105px", width: "110px" }} src={expired} alt="" />
            {/* <h6 style={{color:"black",padding:"8px",borderRadius:"8px",backgroundColor:"white",position:"relative",top:"10px",left:"45px"}}>Expired</h6> */}
          </div> : <></>}
          <div>
            {qrImg ?
              <img src={IMAGE_ENDPOINT + "/" + qrImg} alt='No Image Found' style={{ marginTop: expireDate < today ? "-70px" : "1.8rem", filter: expireDate < today ? "grayScale(100%)" : "", width: "165px", height: "150px", borderRadius: "8px" }} />
              :
              <h5>No Image Found</h5>
            }
          </div>
        </Box>
      </Modal>
    </div>
  )
}
export default Qr_detail