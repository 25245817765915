import React from "react";
import useRazorpay from "react-razorpay";
import { useState, useMemo, useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { payment } from "../actions/PaymentActions";
import logo from "../assets/regal_images/regal_logo.png"
import payment_success from "../assets/images/payment_success.png"
import { useHistory } from 'react-router-dom'
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';

import axios from "axios";
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import check from '../assets/regal_images/check1.gif'
import { ROUTES } from "../constant/routes";
import { toast } from "react-toastify";

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  maxHeight: "70vh",
  width: 400,
  bgcolor: 'rgb(25,25,25)',
  border: '2px solid rgb(255,255,255,0.5)',
  boxShadow: 24,
  borderRadius: "10px",
  // p: 4,
};
export default function RazorpayButton(props) {
  const [open, setOpen] = React.useState(false);

  const handleClose = () => {
    setOpen(false)
    if (isLoggedIn) {
      if (props?.movie) {
        history.replace(`${ROUTES.moviedetails}/${props?.slug}`)
      }
      else if (props?.series) {
        history.replace(`${ROUTES.seriesDetails}/${props?.slug}`)
      }
      else {
        history.replace('/')

      }
      // history.goBack()
      // history.goBack()
    }
  };
  const dispatch = useDispatch()
  const user = JSON.parse(sessionStorage.getItem('user'))
  const isLoggedIn = JSON.parse(sessionStorage.getItem('isLoggedIn'))
  const history = useHistory()
  const [paymentId, setPatmentId] = useState();
  const [Razorpay] = useRazorpay();
  const [form, setForm] = useState({
    date: new Date().toJSON().slice(0, 10).split('-').reverse().join('/'),
    device_id: "",
    device_type: "Web",
    gateway: "RazorPay",
    location: "",
    payment_method: "",
    name: user?.name,
    user: user?.id,
    email: user?.email,
    mobile_no: user?.mobileNumber,
    transaction_type: props.transactionType,
    plan: props.plan || "",
    movie: props.movie || "",
    series: props.series || "",
    payment_amount: props?.price,
    category_type: props?.categoryType,
    promocode: props?.promocode || "",
    ad_free: props?.ad_free
  })
  useMemo(() => {
    setForm({
      ...form,
      name: user?.firstName + " " + user?.lastName,
      user: user?.id,
      email: user?.email,
      mobile_no: user?.mobileNumber,
      transaction_type: props.transactionType,
      plan: props.plan || "",
      movie: props.movie || "",
      series: props.series || "",
      payment_amount: props?.price,
      category_type: props?.categoryType,
      promocode: props?.promocode || "",
    })

  }, [props])
  const getData = async () => {
    const res = await axios.get("https://api.ipify.org/?format=json");

    setForm({ ...form, ip_address: res.data.ip });
  };
  useEffect(() => {
    getData();
  }, []);

  const handlePayment = useCallback((event) => {
    event.preventDefault();
    const options: RazorpayOptions = {
      // key: "rzp_live_PBP8OCK4l4eYZ6",
      key: "rzp_test_8m3Fho730KwaLV",
      // key: "rzp_live_PIl81uokj67B6x",
      amount: Number(form?.payment_amount) * 100,
      currency: props?.currency_type,
      name: "Tygon Fantasy",
      description: form?.transaction_type,
      image: logo,
      //   order_id: order.id,
      handler: (res) => {
        //   handleSubmit(event, res);
        const paymentIds = res.razorpay_payment_id
        setPatmentId(paymentIds)
        // console.log({...form,payment_id:paymentIds})
        dispatch(payment({ ...form, payment_id: paymentIds }));
        setOpen(true)
        setTimeout(() => { handleClose() }, 2000)
      },
      prefill: {
        name: form?.name,
        email: form?.email,
        contact: form?.mobile_no,
      },
      notes: {
        address: "Razorpay Corporate Office",
      },
      theme: {
        color: "red",
      },
    };


    const rzpay = new Razorpay(options);
    rzpay.open();
    rzpay.on(
      "payment.failed",
      function (response) {
        // alert(response.error.code);
        alert(response.error.description);
        // alert(response.error.source);
        // alert(response.error.step);
        // alert(response.error.reason);
        // alert(response.error.metadata.order_id);
        // alert(response.error.metadata.payment_id);
        //   console.log("Response:", body);
      },
      ""[(Razorpay)]
    );
  });
  return (
    <>
      <button
        onClick={(event) => isLoggedIn ? (form?.payment_amount && handlePayment(event)) : toast.error("Please login to continue")}
        // onClick={(event) => isLoggedIn ? (form?.payment_amount && handlePayment(event)) : setOpen(true)}
        className={props?.className}
        style={{ fontSize: "13px" }}
      >
        {props?.title}
      </button>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>

          {/* <div style={{ display: "flex", justifyContent: "center" }}> */}
          {/* <img src={logo} width={"100px"} /> */}
          {/* </div> */}

          {/* <hr /> */}
          {isLoggedIn ? <div className="Success-Popup">
            <div style={{ padding: "28px 0 28px 0", display: "flex", justifyContent: "center", borderRadius: "10px 10px 0 0 " }}>
              <img src={check} style={{ height: "150px", backgroundColor: "rgb(25,25,25)" }} />
              {/* <CheckCircleOutlineIcon style={{ fontSize: "7rem" }} /> */}
            </div>
            <Typography style={{ fontWeight: "590", paddingTop: "1.3rem", paddingBottom: "1.1rem" }} id="modal-modal-title" variant="h4" component="h2" sx={{ mt: 2, textAlign: "center" }}>
              Congragulations!!
            </Typography>
            <Typography className="pb-4" variant="h5" id="modal-modal-description" sx={{ mt: 2, textAlign: "center" }}>
              Payment Succeccful
            </Typography>
          </div> :
            <div className="login-popup mt-3 mb-4" >
              <div style={{ display: "flex", justifyContent: "center" }}>
                <img src={logo} style={{ width: "100px", backgroundColor: "rgb(25,25,25)" }} />

              </div>
              <hr style={{ width: "80%" }} />
              <Typography id="modal-modal-title" variant="h6" component="h2" sx={{ mt: 2, textAlign: "center" }}>
                You are not logged in.<br /> Please login to Continue..
              </Typography>
              <button onClick={() => history.push("/login")} className={`${props?.className} mt-3`} style={{ display: "block", margin: "0 auto" }}>
                Login
              </button>
            </div>}
        </Box>
      </Modal>
    </>
  )
}