import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { bindActionCreators } from 'redux'
import * as MovieAction from '../../../../actions/MovieAction'
import { Card, Container } from 'react-bootstrap'
import { CircularProgress, Grid } from '@mui/material'
import { ROUTES } from '../../../../constant/routes'
import { IMAGE_ENDPOINT } from '../../../../constant/ApiList'
import { Link } from 'react-router-dom'
import empty from '../../../../assets/regal_images/empty-box (1).png'
import InfoIcon from '@mui/icons-material/Info';
import rent_icon from '../../../../assets/regal_images/Rent (1).png'
import { useHistory } from 'react-router-dom/cjs/react-router-dom'

export default function ShortFilms() {
    const dispatch = useDispatch()
    const { getAllShortSeries } = bindActionCreators(MovieAction, dispatch)
    const [movies, setMovies] = useState([])
    const user_id = JSON.parse(sessionStorage.getItem('user'))?.id
    const profile_id = JSON.parse(sessionStorage.getItem('profile'))?.id
    const history = useHistory()

    useEffect(() => {
        (async () => {
            let userObj = {
                profile_id: profile_id || null,
                user_id: user_id || null
            }
            const resData = await getAllShortSeries(userObj)
            setMovies(resData?.data)
        })();
    }, [])

    return (
        <div className='vod-div'>
            <div className='vod-div-1'>
                {movies?.data?.length > 0 ? (
                    movies?.data?.map((movie, index) => (
                        <div key={index} className='vod-div-1_1'>
                            <div>
                                <div className='half-circle-left'></div>
                                <img className='vod-div-1-left-part shine' src={`${IMAGE_ENDPOINT + movie?.series_thumbnail}`} alt={movie?.series_name} />
                            </div>
                            <div className='vod-div-1-right-part'>
                                <div className='half-circle-right'></div>
                                <h5 >{movie?.series_name}</h5>
                                {/* <span >Duration : {movie?.movie_duration}</span> */}
                                <div style={{ display: "flex", alignItems: "center" }}>
                                    <span>Genre:</span>
                                    <h6>{movie?.genre[0].genre_title}</h6>
                                </div>
                                {/* <div style={{ display: "flex", alignItems: "center" }}>
                                    <span>Validity :</span>
                                    <h6> {movie?.available_for_days} Days</h6>
                                </div> */}
                                <p style={{ fontSize: "15px", margin: "0" }} dangerouslySetInnerHTML={{ __html: movie?.series_description?.length <= 130 ? movie?.series_description : movie?.series_description?.substring(0, 130) + "..." }}></p>



                                <div onClick={()=>history.push(ROUTES.seriesDetails + "/" + movie?.slug)} style={{ marginTop: "auto", width: "100%" }} >
                                    <button style={{ marginTop: "auto", width: "100%", display: "flex", gap: "7px", alignItems: "center", justifyContent: "center" }}>
                                        <InfoIcon style={{ color: "#ba000b" }} />
                                        <span style={{ color: "#000000" }}>View more</span>
                                    </button>
                                </div>


                            </div>
                        </div>
                    ))
                ) : (
                    <div style={{ height: "48vh", width: "83vw", display: "flex", alignItems: "center", justifyContent: "center" }}>
                        <img src={empty} alt='empty' style={{ height: "60px" }} />
                        <p className='pt-4'> Sorry, No result found.</p>
                    </div>


                )}

            </div>
        </div>
    )
}
