import { CircularProgress } from '@mui/material'
import React, { useEffect } from 'react'
import { useHistory, useLocation } from 'react-router-dom/cjs/react-router-dom.min'
import { ROUTES } from '../constant/routes'

export default function Loading() {
    const location = useLocation()
    const history = useHistory()
    useEffect(() => {
        if (location?.state?.data?.length) {
            history.replace(ROUTES.episode, { data: location?.state?.data?.[0], nextEpisode: location?.state?.data })

        }

    }, [location?.state])

    return (
        <div>
            <div style={{ height: "100vh" }}>
                <div style={{ position: "relative", textAlign: "center", top: "50%" }}>
                    <CircularProgress style={{ color: "#ba000b" }} />
                </div>
            </div>
        </div>
    )
}
