import React, { useEffect, useState } from 'react'
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';
import * as MovieAction from '../actions/MovieAction'
import { bindActionCreators } from 'redux';
import { useDispatch } from 'react-redux';
import { Link, useHistory } from 'react-router-dom/cjs/react-router-dom';
import { ROUTES } from '../constant/routes';
import { IMAGE_ENDPOINT } from '../constant/ApiList';
import { Grid } from '@mui/material';
import empty from '../assets/regal_images/empty-box (1).png'


const Upcomingmovies = () => {

    const [upcoming, setUpcoming] = useState([])

    const dispatch = useDispatch()
    const user_id = JSON.parse(sessionStorage.getItem('user'))?.id

    const history = useHistory()

    const { getAllUpcomingMovies } = bindActionCreators(MovieAction, dispatch)


    useEffect(() => {
        (async () => {
            const data = await getAllUpcomingMovies({ user_id: user_id || "null" })

            setUpcoming(data?.data)
        })()
    }, [])

    const handleTrailer = (data) => {
        let userObj = {
            movie_name: data?.movie_name,
            Trailer: data?.movie_trailer
        }
        history.push(ROUTES.trailer, { data: userObj })
    }



    return (
        <div className='vod-div'>
            <div className='vod-div-1'>
                {upcoming?.length > 0 ? (
                    upcoming?.map((movie, index) => (
                        <div key={index} className='vod-div-1_1'>
                            <div>
                                <div className='half-circle-left'></div>
                                <img className='vod-div-1-left-part shine' src={`${IMAGE_ENDPOINT + movie?.movie_thumbnail}`} alt={movie?.name} />
                            </div>
                            <div className='vod-div-1-right-part'>
                                <div className='half-circle-right'></div>
                                <h5 >{movie?.movie_name}</h5>
                                {/* <span >Duration : {movie?.movie_duration}</span> */}

                                {/* <div style={{ display: "flex", alignItems: "center" }}>
                                        <span>Validity :</span>
                                        <h6> {movie?.available_for_days} Days</h6>
                                    </div> */}
                                <p style={{ fontSize: "15px", margin: "0" }} dangerouslySetInnerHTML={{ __html: movie?.movie_description?.length <= 100 ? movie?.movie_description : movie?.movie_description?.substring(0, 100) + "..." }}></p>


                                <div style={{ marginTop: "auto", width: "100%" }} >
                                    <p>Releasing on : {new Date(movie?.release_date).toLocaleDateString('en-GB', { timeZone: 'Europe/London' })}</p>

                                    <button onClick={() => handleTrailer(movie)} style={{ marginTop: "auto", width: "100%", display: "flex", gap: "7px", alignItems: "center", justifyContent: "center" }}>
                                        <PlayCircleOutlineIcon style={{ color: "#ffffff" }} />
                                        <span style={{ color: "#ffffff" }}>Play Trailer</span>
                                    </button>
                                </div>


                            </div>
                        </div>
                    ))
                ) : (
                    <div style={{ height: "48vh", width: "83vw", display: "flex", alignItems: "center", justifyContent: "center" }}>
                        <img src={empty} alt='empty' style={{ height: "60px" }} />
                        <p className='pt-4'> Sorry, No result found.</p>
                    </div>


                )}

            </div>
        </div>

    )
}

export default Upcomingmovies