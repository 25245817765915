import React from 'react'
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { ROUTES } from '../constant/routes';
import { useHistory } from 'react-router-dom'

const Navigation_bar = ({ postsPerPage, totalpost, setCurrentPage, currentpage }) => {


    const history = useHistory()

    let pages = [];

    for (let i = 1; i <= Math.ceil(totalpost / postsPerPage); i++) {
        pages.push(i);
    }

    const handlePrevPage = () => {
        if (currentpage > 1) {
            setCurrentPage(currentpage - 1);
        }
    };

    const handleNextPage = () => {
        if (currentpage < (totalpost / postsPerPage)) {
            setCurrentPage(currentpage + 1);
        }
    };

    const goBack = () => {
        history.push(ROUTES.my_account)
    }

    return (
        <>



            <div className='navigation-menu'>
                <div  className='mt-2 back-button' onClick={goBack}><i className="fa fa-arrow-left" aria-hidden="true"></i>  Back</div>
                <button className='pre-next-button' onClick={handlePrevPage}> <ArrowBackIosIcon style={{ fontSize: "1.2rem", marginRight: "-0.4rem" }} /> </button>
                <div>
                    {pages.slice(currentpage - 1, currentpage + 1)?.map((data, index) => (
                        <button style={{
                            backgroundColor: data == currentpage ? "transparent" : "",
                            color: data == currentpage ? "white" : "",
                            border: data == currentpage ? "1px solid white" : ""
                        }} className={data == currentpage ? 'pagination-button active' : 'pagination-button'} key={index} onClick={() => setCurrentPage(data)}>
                            {data}
                        </button>
                    ))}
                </div>
                <button className='pre-next-button' onClick={handleNextPage}> <ArrowForwardIosIcon style={{ fontSize: "1.2rem", marginRight: "-0.2rem", marginLeft: "-0.2rem" }} /> </button>
            </div >
        </>
    );
};
export default Navigation_bar;
