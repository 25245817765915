import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { Dropdown, Row, Col, Container, Button, Card, DropdownButton } from 'react-bootstrap'
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Navigation } from 'swiper';
import 'swiper/swiper-bundle.css';
import { bindActionCreators } from 'redux';
import { useDispatch } from 'react-redux';
import * as MovieAction from '../../../actions/MovieAction'
import * as HomeAction from '../../../actions/HomeActions'

import { CircularProgress, Grid } from '@mui/material';
import { ROUTES } from '../../../constant/routes';
import { IMAGE_ENDPOINT } from '../../../constant/ApiList';
import empty from '../../../assets/regal_images/empty-box (1).png'
import rent_icon from '../../../assets/regal_images/Rent (1).png'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { Base64 } from 'js-base64';
import freetag from '../../../assets/regal_images/freetag.png'
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';
import { useSelector } from 'react-redux';
import playIcon from '../../../assets/regal_images/playIcon.png'


//img

// install Swiper modules
SwiperCore.use([Navigation]);

const MovieList = () => {
    const dispatch = useDispatch()
    const { getAllMovies } = bindActionCreators(MovieAction, dispatch)
    const [movies, setMovies] = useState([])
    const [allMovies, setAllMovies] = useState([])
    const movieData = useSelector((state) => state?.movies?.movies?.data)
    const [genre, setGenre] = useState([])
    const [language, setLanguage] = useState([])
    const history = useHistory()
    // const { getAllHomeData } = bindActionCreators(HomeAction, dispatch)
    const [accessFilter, setAccessFilter] = useState('ALL')
    const [home, setHome] = useState([])

    const data1 = useSelector((state) => state?.movies?.genre?.genre_list)
    const languageData = useSelector((state) => state?.movies?.language?.language_list)


    const profile_id = JSON.parse(sessionStorage.getItem('profile'))?.id
    const user_id = JSON.parse(sessionStorage.getItem('user'))?.id


    useEffect(() => {
        (async () => {
            let userObj = {
                category_name: "VIDEOS",
                user_id: user_id || "null"
            }
            const resData = await getAllMovies(userObj)
            setMovies(resData?.data)
            setAllMovies(resData?.data)
            let user = {
                user_id: user_id ? user_id : "null",
                profile_id: profile_id ? profile_id : "null"
            }
            // const Homedata = await getAllHomeData(user)
            // setHome({ ...Homedata, data: JSON.parse(Base64.decode(Homedata.data)) })

            // setLanguage(Homedata?.data?.Language)
            // setGenre(Homedata?.data?.Genre)

        })();
    }, [])

    const handleLanguage = (data) => {
        history.push(ROUTES.moviesByLanguage, { id: data?.id })
    }
    const handleGenre = (data) => {
        history.push(ROUTES.moviesByGenre, { id: data?.id })

    }

    const handleAccessFilter = (MovieType) => {
        if (MovieType !== "ALL") {
            let localArr = []
            setMovies([])
            allMovies?.filter((data) => {

                if (data?.movie_access == MovieType) {
                    localArr.push(data)
                }
            })
            setMovies(localArr)
        }
        else {
            setMovies(allMovies)
        }

    }
    return (
        <>
            <section className="m-profile setting-wrapper">
                <div className='movie-main-div '>
                    <div className='movielist-dropdwn' style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                        <h4 className="main-title mb-5 mt-3">Movies</h4>

                    </div>

                    <div className='movie-page-grid'>
                        {
                            movies?.length ?
                                movies?.map((data, index) => (
                                    <div key={index} className='animated fadeInUp '>
                                        <Link to={`${ROUTES.moviedetails}/${data?.slug}`}>
                                            <div className="thumbnail-container">
                                                <Link to={`${ROUTES.moviedetails}/${data?.slug}`}>
                                                    <img src={`${IMAGE_ENDPOINT}/${data?.movie_thumbnail}`} className={`thumb-img1`} alt={data?.movie_name} />
                                                    {
                                                        data?.movie_access == "FREE" &&
                                                        <div style={{ position: "absolute", top: "-3px", right: "-7px" }}>
                                                            <img src={freetag} style={{ width: "70px" }} />
                                                        </div>
                                                    }
                                                </Link>
                                                <div className='hover-class1'>
                                                    <div style={{ display: "flex", gap: "8px", alignItems: "center" }}>
                                                        {/* <PlayCircleOutlineIcon style={{ fontSize: "1.6rem", color: "#ba000b" }} /> */}
                                                        <img src={playIcon} style={{ width: "20px" }} />

                                                        <span style={{ fontSize: "1.2rem", color: "white" }}>Watch Now</span>
                                                    </div>
                                                    <div style={{ color: "white", fontSize: "0.95rem" }}>
                                                        {data?.movie_name}
                                                    </div>
                                                    {/* <div style={{ color: "white", fontSize: "0.95rem" }}>
                                                        <img src={gujjuCoin} alt='coin' style={{ width: "20px" }} />
                                                        {" " + data?.movie_coin} Coins
                                                    </div> */}

                                                </div>
                                            </div>
                                        </Link>
                                    </div>
                                ))
                                :
                                <></>

                        }

                    </div>
                    {
                        !movies?.length && movieData?.length == 0 ?
                            <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                                <div className='mt-5' style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                                    <img src={empty} alt='empty' style={{ height: "60px" }} />
                                    <p className='pt-4'> Sorry, No result found.</p>
                                    {/* <CircularProgress /> */}
                                </div>
                            </Grid>
                            : (!movies?.length) ?
                                <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                                    <div className='mt-5' style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>

                                        <CircularProgress sx={{ color: "#ba000b" }} />
                                    </div>
                                </Grid>
                                :
                                <></>
                    }
                </div>
            </section>
        </>
    )
}
export default MovieList;