import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux';
import { bindActionCreators } from 'redux';
// import SwiperCore, { Navigation } from 'swiper';
import * as MovieAction from '../../../actions/MovieAction'
import { IMAGE_ENDPOINT } from '../../../constant/ApiList';
import { CircularProgress, Grid, Popover, Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import Share from '../common/Share';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Autoplay, Pagination, Navigation } from "swiper";
import { Container, Col, Row, Nav, Tab, } from 'react-bootstrap'
import { gsap } from 'gsap'
import freetag from '../../../assets/regal_images/freetag.png'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { ROUTES } from '../../../constant/routes';
import { toast } from 'react-toastify';
import playIcon from '../../../assets/regal_images/playIcon.png'

// install Swiper modules
SwiperCore.use([Navigation]);
const gsapAnimate = {
    getData: (elem) => {
        const option = {
            opacity: 0,
            scale: 1,
            position: {
                x: 0,
                y: 0,
            },
            ease: "",
            duration: 1,
            delay: .4,
            rotate: 0
        }
        if (elem !== undefined) {
            option.position.x = gsapAnimate.validValue(elem.dataset.iqPositionX, 0)
            option.position.y = gsapAnimate.validValue(elem.dataset.iqPositionY, 0)
            option.rotate = gsapAnimate.validValue(elem.dataset.iqRotate, 0)
            option.scale = gsapAnimate.validValue(elem.dataset.iqScale, 1)
            option.opacity = gsapAnimate.validValue(elem.dataset.iqOpacity, 0)
            option.delay = gsapAnimate.validValue(elem.dataset.iqDelay, .4)
            option.duration = gsapAnimate.validValue(elem.dataset.iqDuration, 1.5)
            option.ease = gsapAnimate.validValue(elem.dataset.iqEase, '')
            const setOption = { opacity: option.opacity, scale: option.scale, x: option.position.x, y: option.position.y, ease: option.ease, rotate: option.rotate, duration: option.duration, delay: option.delay }
            return setOption
        } else {
            return { opacity: 0 }
        }
    },
    onStart: (elem) => {
        const setOption = gsapAnimate.getData(elem)
        gsap.from(elem, setOption)
    },
    onEnd: (elem) => {
        const setOption = gsapAnimate.getData(elem)
        gsap.to(elem, setOption)
    },

    onStartEnd: (elem) => {
        const setOption = gsapAnimate.getData(elem)
        const setEndOption = gsapAnimate.getData(elem)
        setEndOption.opacity = 1
        setEndOption.x = 0
        setEndOption.y = 0
        setEndOption.rotate = 0
        setEndOption.scale = 1
        gsap.fromTo(elem, setOption, setEndOption)
    },
    validValue: (attr, defaultVal) => {
        if (attr !== undefined && attr !== null) {
            return Number(attr)
        }
        return Number(defaultVal)
    }
}
const Series = () => {
    const dispatch = useDispatch()
    const { getAllSeries } = bindActionCreators(MovieAction, dispatch)
    const [series, setSeries] = useState([])
    const profile_id = JSON.parse(sessionStorage.getItem('profile'))?.id
    const [anchorEl, setAnchorEl] = React.useState(null);
    const history = useHistory()
    const isLoggedIn = JSON.parse(sessionStorage.getItem('isLoggedIn'))
    const user_id = JSON.parse(sessionStorage.getItem('user'))?.id
    const { addIntoWatchList } = bindActionCreators(MovieAction, dispatch)
    const { removeFromWatchlist } = MovieAction
    const [save, setSave] = useState(false)

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;
    useEffect(() => {
        (async () => {
            // if (profile_id) {
            let userObj = {
                profile_id: profile_id ? profile_id : null,
                user_id: user_id || "null"
            }
            const resData = await getAllSeries(userObj)
            setSeries(resData)
            // }

        })();
    }, [save])

    const animationInit = () => {
        if (document.querySelector('.swiper-container .swiper-slide-active') !== null) {

            const gsapElem = document.querySelector('.swiper-container .swiper-slide-active').querySelectorAll('[data-iq-gsap="onStart"]')

            Array.from(gsapElem, (elem) => {
                return gsapAnimate.onStartEnd(elem)
            })
        }
    }

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    }
    const handleSliderClick = (data) => {
        history.push(ROUTES.seriesDetails, { series: data.series.id })
    }

    const addSeriesIntoWatchList = async (id) => {
        if (isLoggedIn && profile_id) {
            let userObj = {
                profile_ID: profile_id,
                series: id
            }
            const res = await addIntoWatchList(userObj)
            if (res?.statuscode == 200) {
                toast.success(res?.message)
                setSave(!save)
            }
            else {
                toast.error("Something went wrong.")
            }
        }
        else {
            toast.error("Please login to continue.")
        }
    }

    const RemoveWatchList = async (id) => {
        if (isLoggedIn && profile_id) {
            let userObj = {
                profile_id: profile_id,
                movie_id: "",
                series_id: id
            }
            const res = await removeFromWatchlist(userObj)
            if (res.statuscode == 200) {
                toast.success(res?.message)
                setSave(!save)
            }
        }
        else {
            toast.error("Please login to continue.")
        }
    }


    return (
        <>

            <section className="main-content">
                <Container fluid>
                    <h4 className=" " style={{ marginTop: "5rem" }}>Web Series</h4>
                    {/* <Grid container spacing={2}>
                        {
                            series?.data?.data?.length ?
                                series?.data?.data?.map((data, index) => (
                                    <Grid item lg={4} xl={4} md={6} sm={6} xs={12} key={index} className='mt-4 series-div-list'>
                                        <Link to={`${ROUTES.seriesDetails}/${data?.slug}`}>
                                            <img src={`${IMAGE_ENDPOINT + data?.series_poster}`} className="series-img" style={{ cursor: "pointer" }} alt={data?.series_name} />

                                        </Link>

                                    </Grid>
                                ))
                                :
                                <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                                    <div className='mt-5 mb-5' style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                                        <img src={empty} alt='empty' style={{ height: "60px" }} />
                                        <p className='pt-4'> Sorry, No result found.</p>
                                    </div>
                                </Grid>
                        }

                    </Grid> */}


                    <div className='movie-page-grid'>
                        {
                            series?.data?.data?.length ?
                                series?.data?.data?.map((data, index) => (
                                    <div key={index} className='animated fadeInUp '>
                                        <Link to={`${ROUTES.seriesDetails}/${data?.slug}`}>
                                            <div className="thumbnail-container">
                                                <Link to={`${ROUTES.seriesDetails}/${data?.slug}`}>
                                                    <img src={`${IMAGE_ENDPOINT}/${data?.series_thumbnail}`} className={`thumb-img1`} alt={data?.series_name} />
                                                    {
                                                        data?.series_type == "FREE" &&
                                                        <div style={{ position: "absolute", top: "-3px", right: "-7px" }}>
                                                            <img src={freetag} style={{ width: "70px" }} />
                                                        </div>
                                                    }
                                                </Link>
                                                <div className='hover-class1'>
                                                    <div style={{ display: "flex", gap: "8px", alignItems: "center" }}>
                                                        {/* <PlayCircleOutlineIcon style={{ fontSize: "1.6rem", color: "#ba000b" }} /> */}
                                                        <img src={playIcon} style={{ width: "20px" }} />

                                                        <span style={{ fontSize: "1.2rem", color: "white" }}>Watch Now</span>
                                                    </div>
                                                    <div style={{ color: "white", fontSize: "0.95rem" }}>
                                                        {data?.series_name}
                                                    </div>
                                                    {/* <div style={{ color: "white", fontSize: "0.95rem" }}>
                                                        <img src={gujjuCoin} alt='coin' style={{ width: "20px" }} />
                                                        {" " + data?.movie_coin} Coins
                                                    </div> */}

                                                </div>
                                            </div>
                                        </Link>
                                    </div>
                                ))
                                :
                                <></>

                        }

                    </div>

                </Container>
            </section>
        </>
    )
}
export default Series;