import React, { useEffect, useRef, useState } from 'react'
import { Container, Col, Row, Nav, Tab, Button, } from 'react-bootstrap'
import { Link } from 'react-router-dom'
// parallax
import parallax3 from '../../../assets/images/parallax/p1.jpg'
import parallax4 from '../../../assets/images/parallax/parallax-logo.png'
import { IMAGE_ENDPOINT } from '../../../constant/ApiList'
import { ROUTES } from '../../../constant/routes'
import { useHistory } from 'react-router-dom'

import logo from '../../../assets/regal_images/regal_logo.png'
import * as MovieAction from '../../../actions/MovieAction'

import Typography from '@mui/material/Typography';
import { Box } from '@mui/material';
import Modal from '@mui/material/Modal';
import { bindActionCreators } from 'redux'
import { useDispatch } from 'react-redux'



const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    maxHeight: "70vh",
    width: 400,
    bgcolor: 'rgb(25,25,25)',
    border: '2px solid rgb(255,255,255,0.5)',
    boxShadow: 24,
    borderRadius: "10px",
    p: 4,
};

const styleforAlert = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    maxHeight: "70vh",
    width: 600,
    bgcolor: 'rgb(25,25,25)',
    border: '2px solid rgb(255,255,255,0.5)',
    boxShadow: 24,
    borderRadius: "10px",
    p: 4,
}

function MovieBanner(props) {
    const targetRef = useRef(null);
    const dispatch = useDispatch()

    const history = useHistory()
    const [popup18Plus, setPopup18Plus] = useState(false)
    const [hoursLeft, setHoursLeft] = useState(calculateHoursLeft());
    const [hoursAlert, setHoursAlert] = useState(false)
    const [completedHour, setCompletedHour] = useState(false)
    const { setCompleteHours } = MovieAction
    const [animation, setAnimation] = useState(false)
    const { webDashboardPromotion } = bindActionCreators(MovieAction, dispatch)
    const [promotionData, setPromotionData] = useState([])


    const profile_id = JSON.parse(sessionStorage.getItem('profile'))?.id
    const user_id = JSON.parse(sessionStorage.getItem('user'))?.id




    useEffect(() => {
        (async () => {

            const resData = await webDashboardPromotion({ user_id: user_id || null })
            if (resData?.data?.list_promotion?.[0]?.movie?.platform !== "App") {
                setPromotionData(resData?.data);

            }
          
        })();
    }, [])



    useEffect(() => {
        const intervalId = setInterval(() => {
            setHoursLeft(calculateHoursLeft());
        }, 1000);
    }, [])
    const isRunningInElectron = navigator.userAgent.toLowerCase().indexOf(' electron/') > -1;

    useEffect(() => {
        const options = {
            root: null, // Use the viewport as the root
            rootMargin: '0px', // No margin
            threshold: 0.5, // Trigger the callback when 50% of the element is in view
        };

        const observer = new IntersectionObserver((entries) => {
            entries.forEach((entry) => {
                if (entry.isIntersecting) {
                    // Call your function when the target div is in view
                    yourFunction();
                    // Stop observing to avoid multiple calls
                    observer.unobserve(entry.target);
                }
            });
        }, options);

        if (targetRef.current) {
            observer.observe(targetRef.current);
        }

        // Cleanup observer when component is unmounted
        return () => {
            observer.disconnect();
        };
    }, []); // Empty dependency array to run effect once on mount

    const yourFunction = () => {
        setAnimation(true)
        // Add your function logic here
    };


    function calculateHoursLeft() {
        const now = new Date();
        const nextDay = new Date(now);
        nextDay.setDate(now.getDate() + 1);
        nextDay.setHours(0, 0, 0, 0);

        const timeDifference = nextDay - now;
        const hoursLeft = Math.floor(timeDifference / (1000 * 60 * 60));

        return hoursLeft;
    }

  
    const handleRedirect = (type) => {
        if (type == "Series") {
            history.push(ROUTES.seriesDetails + "/" + promotionData?.list_promotion[0]?.series?.slug)
        }
        else {
            history.push(`${ROUTES.moviedetails}/${promotionData?.list_promotion[0]?.movie?.slug}`)
        }
    }
    const releaseDate = new Date(props?.promotion?.data[0]?.release_date + "T" + props?.promotion?.data[0]?.publish_time + "Z")


 
    return (
        <>

            {
                promotionData?.list_promotion?.length ?
                    <section id="parallex" className="parallax-window">
                        <Container fluid className="h-100">
                            <Row className="align-items-center justify-content-center h-100 parallaxt-details">
                                <Col lg="6">
                                    <div onClick={() => handleRedirect(promotionData?.list_promotion[0]?.video_type)}>

                                        <div ref={targetRef} id='img-content' className={`parallax-img-x ${animation ? "start-animation" : ""}`}>
                                            <img src={`${IMAGE_ENDPOINT + promotionData?.list_promotion[0]?.promotion_image_web}`} className="promo-img" alt="bailey" />
                                        </div>
                                    </div>
                                </Col>
                                <Col lg="6" className={`r-mb-23 ${animation ? "start-animation" : ""}`}>
                                    <div className="text-left">
                                        <div onClick={() => handleRedirect(promotionData?.list_promotion[0]?.video_type)}>
                                            <h1 className='trending-text big-title text-uppercase mt-0 mb-2'>{((promotionData?.list_promotion[0]?.video_type) == "Series") ? promotionData?.list_promotion[0]?.series?.series_name + "- Web Series" : promotionData?.list_promotion[0]?.movie?.movie_name + ""}</h1>
                                        </div>
                                        {
                                            promotionData?.list_promotion[0]?.video_type !== "Series" ?
                                                <div className='mt-2'>
                                                    <h5>Duration : {promotionData?.list_promotion[0]?.movie?.movie_duration}</h5>
                                                </div>
                                                :
                                                <div className='mt-2'>
                                                    <h5>Total Episodes : {promotionData?.list_promotion[0]?.episode_count}</h5>
                                                </div>
                                        }

                                        <div className='mt-5'>
                                            <button style={{
                                                padding: "15px",
                                                paddingLeft: "25px",
                                                paddingRight: "25px",
                                                background: "transparent",
                                                color: "#ffffff",
                                                border: "2px solid #ffffff",
                                                borderRadius: "1rem",
                                                cursor: "pointer"
                                            }}
                                                onClick={() => handleRedirect(promotionData?.list_promotion[0]?.video_type)}
                                            >
                                                <i className="fa fa-play mr-2" aria-hidden="true"></i>
                                                Watch now
                                            </button>
                                        </div>
                                        {/* </Link> */}

                                    </div>
                                </Col>

                            </Row>
                        </Container>
                    </section >
                    :
                    <></>
            }

        </>
    )
}

export default MovieBanner
