import React, { useState } from 'react'
import { Container } from 'react-bootstrap'
import { auth } from './firebase.config'
import { RecaptchaVerifier, signInWithPhoneNumber } from 'firebase/auth'
import { error } from 'jquery'


export default function Faq() {
   const [otp, setOtp] = useState("")
   const [mobile, setMobile] = useState("")
   const [showOtp, setShowOtp] = useState(false)
   const [user, setUser] = useState("")

   const onCaptchVerify = () => {
      try {
         if (!window.recaptchaVerifier) {
            window.recaptchaVerifier = new RecaptchaVerifier(auth, 'recaptcha-container', {
               'size': 'invisible',
               'callback': (response) => {
                  onSignup()
               },
               'expired-callback': () => {
                  // Response expired. Ask user to solve reCAPTCHA again.
                  // ...
               }
            });
         }
      } catch (error) {
         console.error("Error in onCaptchaVerify:", error);
      }

   }

   function onSignup() {
      onCaptchVerify()
      const appVerifier = window.recaptchaVerifier
      const formatph = '+91' + mobile
      signInWithPhoneNumber(auth, formatph, appVerifier)
         .then((confirmationResult) => {
            alert("OTP send")
            window.confirmationResult = confirmationResult;
            // ...
         }).catch((error) => {
            console.log("error", error)
         });
   }

   function onOTPVerify() {
      window.confirmationResult.confirm(otp).then(async (res) => {
         setUser(res.user)
      }).catch((err) => {
         console.log("err", err.code)
         if (err.code === 'auth/invalid-verification-code') {
            alert("Invalid OTP")
         }else{
            alert("Something went wrong")
         }
         })
   }
   return (
      <Container className='mt-5'>
         <div id='recaptcha-container'></div>
         <input type='text' value={mobile} onChange={(e) => setMobile(e.target.value)} />
         <button onClick={onSignup}>Submit</button>


         <input type='text' value={otp} onChange={(e) => setOtp(e.target.value)} />
         <button onClick={onOTPVerify}>Verify OTP</button>
      </Container>
   )
}
