import React from 'react'
import { Container } from 'react-bootstrap'
import { Link, useLocation } from 'react-router-dom/cjs/react-router-dom.min'
import { ROUTES } from '../../../constant/routes'
import { IMAGE_ENDPOINT } from '../../../constant/ApiList'
import playIcon from '../../../assets/regal_images/playIcon.png'
import freetag from '../../../assets/regal_images/freetag.png'


export default function SeriesByCategory() {
    const location = useLocation()

    return (
        <div>
            <section className="main-content">
                <Container fluid>
                    <h4 className="mb-5" style={{ marginTop: "5rem" }}>{location?.state?.name}</h4>
                    {/* <Grid container spacing={2}>
                        {
                            series?.data?.data?.length ?
                                series?.data?.data?.map((data, index) => (
                                    <Grid item lg={4} xl={4} md={6} sm={6} xs={12} key={index} className='mt-4 series-div-list'>
                                        <Link to={`${ROUTES.seriesDetails}/${data?.slug}`}>
                                            <img src={`${IMAGE_ENDPOINT + data?.series_poster}`} className="series-img" style={{ cursor: "pointer" }} alt={data?.series_name} />

                                        </Link>

                                    </Grid>
                                ))
                                :
                                <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                                    <div className='mt-5 mb-5' style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                                        <img src={empty} alt='empty' style={{ height: "60px" }} />
                                        <p className='pt-4'> Sorry, No result found.</p>
                                    </div>
                                </Grid>
                        }

                    </Grid> */}


                    <div className='movie-page-grid'>
                        {
                            location?.state?.data?.length ?
                                location?.state?.data?.map((data, index) => (
                                    <div key={index} className='animated fadeInUp '>
                                        <Link to={`${ROUTES.seriesDetails}/${data?.slug}`}>
                                            <div className="thumbnail-container">
                                                <Link to={`${ROUTES.seriesDetails}/${data?.slug}`}>
                                                    <img src={`${IMAGE_ENDPOINT}/${data?.series_thumbnail}`} className={`thumb-img1`} alt={data?.series_name} />
                                                    {
                                                        data?.series_type == "FREE" &&
                                                        <div style={{ position: "absolute", top: "-3px", right: "-7px" }}>
                                                            <img src={freetag} style={{ width: "70px" }} />
                                                        </div>
                                                    }
                                                </Link>
                                                <div className='hover-class1'>
                                                    <div style={{ display: "flex", gap: "8px", alignItems: "center" }}>
                                                        {/* <PlayCircleOutlineIcon style={{ fontSize: "1.6rem", color: "#ba000b" }} /> */}
                                                        <img src={playIcon} style={{ width: "20px" }} />

                                                        <span style={{ fontSize: "1.2rem", color: "white" }}>Watch Now</span>
                                                    </div>
                                                    <div style={{ color: "white", fontSize: "0.95rem" }}>
                                                        {data?.series_name}
                                                    </div>
                                                    {/* <div style={{ color: "white", fontSize: "0.95rem" }}>
                                                        <img src={gujjuCoin} alt='coin' style={{ width: "20px" }} />
                                                        {" " + data?.movie_coin} Coins
                                                    </div> */}

                                                </div>
                                            </div>
                                        </Link>
                                    </div>
                                ))
                                :
                                <></>

                        }

                    </div>

                </Container>
            </section>
        </div>
    )
}
