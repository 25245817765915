

//home page data

export const GET_ALL_HOME_DATA = "GET_ALL_HOME_DATA"
export const GET_ALL_HOME_DATA_ERROR = "GET_ALL_HOME_DATA_ERROR"

export const GET_SLIDER_DATA = "GET_SLIDER_DATA"
export const GET_SLIDER_DATA_ERROR = "GET_SLIDER_DATA_ERROR"

//Movie details api

export const GET_MOVIE_DETAILS = "GET_MOVIE_DETAILS"
export const GET_MOVIE_DETAILS_ERROR = "GET_MOVIE_DETAILS_ERROR"

//series details
export const GET_SERIES_DETAILS = "GET_SERIES_DETAILS"
export const GET_SERIES_DETAILS_ERROR = "GET_SERIES_DETAILS_ERROR"

//Episode
export const GET_EPISODE_DETAILS = "GET_EPISODE_DETAILS"
export const GET_EPISODE_DETAILS_ERROR = "GET_EPISODE_DETAILS_ERROR"

//Subscription page 
export const GET_SUBSCRIPTION_DETAILS = "GET_SUBSCRIPTION_DETAILS"
export const GET_SUBSCRIPTION_DETAILS_ERROR = "GET_SUBSCRIPTION_DETAILS_ERROR"

//promotion
export const GET_PROMOTION_DETAIL = "GET_SUBSCRIPTION_DETAILS"


//qrcode detail
export const GET_QR_DETAIL = "GET_QR_DETAIL"
export const GET_USER_REDEEM_HISTORY = "GET_USER_REDEEM_HISTORY"


//highlilght detail
export const GET_ALL_HIGHLIGHTS_DATA = "GET_ALL_HIGHLIGHTS_DATA"


//qr reddem
export const GET_QR_REDEEM_DATA = "GET_QR_REDEEM_DATA"


//watchlist 
export const GET_USER_WATCHLIST = "GET_USER_WATCHLIST"
export const GET_USER_WATCHLIST_ERROR = "GET_USER_WATCHLIST_ERROR"

//coin
export const GET_COIN_DETAILS = "GET_COIN_DETAILS"
export const GET_COIN_DETAILS_ERROR = "GET_COIN_DETAILS_ERROR"

export const ADD_MOVIE_IN_WATCHLIST = "ADD_MOVIE_IN_WATCHLIST"
export const ADD_MOVIE_IN_WATCHLIST_ERROR = "ADD_MOVIE_IN_WATCHLIST_ERROR"

//Registration page 
export const REGISTER_MESSAGE = "REGISTER_MESSAGE"
export const EDIT_USER_DETAILS_MESSAGE = "EDIT_USER_DETAILS_MESSAGE"
export const CHANGE_PASSWORD_MESSAGE = "CHANGE_PASSWORD_MESSAGE"
export const FORGOT_PASSWORD_MESSAGE = "FORGOT_PASSWORD_MESSAGE"
export const OTP_MESSAGE = "OTP_MESSAGE"
export const LOGIN_MESSAGE = "LOGIN_MESSAGE"
export const LOGOUT_MESSAGE = "LOGOUT_MESSAGE"
export const PROFILE_MESSAGE = "PROFILE_MESSAGE"
export const FORGOT_PROFILE_PIN_MESSAGE = "FORGOT_PROFILE_PIN_MESSAGE"
export const COMPLAINT_MESSAGE = "COMPLAINT_MESSAGE"
export const PAYMENT_MESSAGE = "PAYMENT_MESSAGE"


//all movies
export const GET_ALL_MOVIES = "GET_ALL_MOVIES"
export const GET_ALL_UPCOMING_MOVIES = "GET_ALL_UPCOMING_MOVIES"
export const GET_ALL_MOVIES_ERROR = "GET_ALL_MOVIES_ERROR"
export const GET_TVOD_MOVIE = "GET_TVOD_MOVIE"
export const GET_ALL_SUBCATEGORY = "GET_ALL_SUBCATEGORY"
export const GET_ALL_SHORT_SERIES = "GET_ALL_SHORT_SERIES"

//genre and language
export const GET_ALL_GENRE = "GET_ALL_GENRE"
export const GET_ALL_LANGUAGE = "GET_ALL_LANGUAGE"


export const GET_UPCOMING_MOVIES = "GET_UPCOMING_MOVIES"
export const GET_UPCOMING_MOVIES_ERROR = "GET_UPCOMING_MOVIES_ERROR"

//series 
export const GET_ALL_SERIES = "GET_ALL_SERIES"
export const GET_ALL_SERIES_ERROR = "GET_ALL_SERIES_ERROR"

//continue watching
export const GET_CONTINUE_WATCHING = "GET_CONTINUE_WATCHING"
export const GET_CONTINUE_WATCHING_ERROR = "GET_CONTINUE_WATCHING_ERROR"

export const ADD_CONTINUE_WATCHING = "ADD_CONTINUE_WATCHING"
export const ADD_CONTINUE_WATCHING_ERROR = "ADD_CONTINUE_WATCHING_ERROR"

//movie by language
export const GET_ALL_MOVIES_BY_LANGUAGE = "GET_ALL_MOVIES_BY_LANGUAGE"
export const GET_ALL_MOVIES_BY_LANGUAGE_ERROR = "GET_ALL_MOVIES_BY_LANGUAGE_ERROR"

//movie by genre
export const GET_ALL_MOVIES_BY_GENRE = "GET_ALL_MOVIES_BY_GENRE"
export const GET_ALL_MOVIES_BY_GENRE_ERROR = "GET_ALL_MOVIES_BY_GENRE_ERROR"
//Avatars  
export const AVATARS = "AVATARS"
//PROFILES  
export const PROFILES = "PROFILES"

export const PRIVACY_POLICY = "PRIVACY_POLICY"
export const TERMS_CONDITIONS = "TERMS_CONDITIONS"
export const REFUND_POLICY = "REFUND_POLICY"
export const ABOUT_US = "ABOUT_US"

//Live streaming data

export const LIVE_STREAMING_DASHBOARD_DATA = "LIVE_STREAMING_DASHBOARD_DATA"
export const LIVE_STREAMING_DASHBOARD_DATA_ERROR = "LIVE_STREAMING_DASHBOARD_DATA_ERROR"
export const GET_STREAM_DETAILS = "GET_STREAM_DETAILS"
export const GET_STREAM_DETAILS_ERROR = "GET_STREAM_DETAILS_ERROR"

export const LOGIN_WITH_OTP = "LOGIN_WITH_OTP"
export const LOGIN_WITH_OTP_ERROR = "LOGIN_WITH_OTP_ERROR"

export const SAVE_USER_DETAILS = "SAVE_USER_DETAILS"
export const SAVE_USER_DETAILS_ERROR = "SAVE_USER_DETAILS_ERROR"
// TRANSACTIONS
export const TRANSACTIONS = "TRANSACTIONS"

//User details
export const USER_DETAILS = "USER_DETAILS"

// PROMOCODE
export const PROMOCODE = "PROMOCODE"
export const PAYMENT_LIST = "PAYMENT_LIST"

// NOTIFICATIONS
export const NOTIFICATIONS = "NOTIFICATIONS"
// USER_LOGIN_LIST
export const USER_LOGIN_LIST = "USER_LOGIN_LIST"
// NOTIFICATIONS
export const USER_LOGOUT_MESSAGE = "USER_LOGOUT_MESSAGE"
// RUNNING_STATUS
export const RUNNING_STATUS = "RUNNING_STATUS"

//recommended movies
export const GET_RECOMMENDED_MOVIES = "GET_RECOMMENDED_MOVIES"
export const GET_RECOMMENDED_MOVIES_ERROR = "GET_RECOMMENDED_MOVIES_ERROR"

//leaving soon movie
export const GET_LEAVING_SOON_MOVIES = "GET_LEAVING_SOON_MOVIES"
export const GET_LEAVING_SOON_MOVIES_ERROR = "GET_LEAVING_SOON_MOVIES_ERROR"

export const WEB_LOGIN_USING_EMAIL = "WEB_LOGIN_USING_EMAIL"
export const WEB_LOGIN_USING_EMAIL_ERROR = "WEB_LOGIN_USING_EMAIL_ERROR"

//payment
export const CASHFREE_PAYMENT_INITIATE = "CASHFREE_PAYMENT_INITIATE"