import React, { useEffect, useState } from 'react'
import { Container, Col, Row, Nav, Tab, Card, } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { Swiper, SwiperSlide } from 'swiper/react';

import { IMAGE_ENDPOINT } from '../../../constant/ApiList';
import { useHistory } from 'react-router-dom';
import { ROUTES } from '../../../constant/routes';
import { Grid } from '@mui/material';
import { bindActionCreators } from 'redux';
import { useDispatch } from 'react-redux';
import * as MovieAction from '../../../actions/MovieAction'
import playIcon from '../../../assets/regal_images/playIcon.png'
import { toast } from 'react-toastify';
import * as HomeAction from '../../../actions/HomeActions';
import { Base64 } from 'js-base64';
import freetag from '../../../assets/regal_images/freetag.png';


export default function WebSeries(props) {
    const history = useHistory()
    const isLoggedIn = JSON.parse(sessionStorage.getItem('isLoggedIn'))
    const profile_id = JSON.parse(sessionStorage.getItem('profile'))?.id
    const user_id = JSON.parse(sessionStorage.getItem('user'))?.id
    const dispatch = useDispatch()
    const { addIntoWatchList, getAllSeries } = bindActionCreators(MovieAction, dispatch)
    const { getAllHomeData } = bindActionCreators(HomeAction, dispatch)
    const [home, setHome] = useState([])

    const { removeFromWatchlist } = MovieAction
    const [updatedArry, setUpdatedArray] = useState([])
    const [save, setSave] = useState(false)
    const handleSeriesRedirect = (id) => {
        history.push(ROUTES.seriesDetails, { series: id })
    }

    // useEffect(() => {
    //     (async () => {
    //         let userObj = {
    //             user_id: user_id ? user_id : "null",
    //             profile_id: profile_id ? profile_id : "null"
    //         }
    //         const resData = await getAllSeries(userObj)
    //         // setUpdatedArray(resData?.data?.Series)
    //         setUpdatedArray(resData?.data)


    //     })();
    // }, [save])


    const addSeriesIntoWatchList = async (id) => {
        if (isLoggedIn && profile_id) {
            let userObj = {
                profile_ID: profile_id,
                series: id
            }
            const res = await addIntoWatchList(userObj)
            if (res?.statuscode == 200) {
                toast.success(res?.message)
                setSave(!save)
            }
            else {
                toast.error("Something went wrong.")
            }
        }
        else {
            toast.error("Please login to continue.")
        }
    }
    const RemoveWatchList = async (id) => {
        if (isLoggedIn && profile_id) {
            let userObj = {
                profile_id: profile_id,
                movie_id: "",
                series_id: id
            }
            const res = await removeFromWatchlist(userObj)
            if (res.statuscode == 200) {
                toast.success(res?.message)
                setSave(!save)
            }
        }
        else {
            toast.error("Please login to continue.")
        }
    }


    return (
        <div className='main-content'>
            <section id="iq-favorites">
                <Container fluid>
                    <Row>
                        <Col sm="12" className="overflow-hidden">
                            <div className="d-flex align-items-center justify-content-between">
                                <h6 style={{ textTransform: "uppercase", color: "red" }} className="main-title">{props?.category?.subcategory_name}</h6>
                                {
                                    props?.category?.video?.length > 7 &&
                                    <div onClick={() => history.replace(ROUTES.seriesByCategory, { data: props?.category?.video, name: props?.category?.subcategory_name })} className="iq-view-all" style={{ cursor: "pointer" }} to={ROUTES.seriesByCategory}>View All</div>

                                }
                            </div>
                            <div id="">
                                {/* <div id="prev4" className="swiper-button swiper-button-prev"><i className="fa fa-chevron-left"></i></div>
                                <div id="next4" className="swiper-button swiper-button-next"><i className="fa fa-chevron-right"></i></div> */}
                                <Swiper
                                    navigation={{
                                        prevEl: '#prev4',
                                        nextEl: '#next4'
                                    }}
                                    breakpoints={{
                                        320: { slidesPerView: 2, spaceBetween: 12 },
                                        450: { slidesPerView: 3 },
                                        638: { slidesPerView: 3 },
                                        810: { slidesPerView: 4 },
                                        1048: { slidesPerView: 5 },
                                        1300: { slidesPerView: 6 },
                                        1600: { slidesPerView: 7, spaceBetween: 9 },
                                        1650: { slidesPerView: 8 },
                                        2050: { slidesPerView: 9 },
                                        2230: { slidesPerView: 10 },
                                        2415: { slidesPerView: 11 },
                                    }}
                                    loop={false}
                                    // slidesPerView={4}
                                    spaceBetween={12}
                                    as="ul"
                                    className="favorites-slider list-inline  row p-0 m-0 iq-rtl-direction mt-3">
                                    {

                                        props?.category?.video.map((data, index) => (
                                            <SwiperSlide as="li" key={index}>
                                                <div className="thumbnail-container">
                                                    <Link to={`${ROUTES.seriesDetails}/${data?.slug}`}>
                                                        <img src={`${IMAGE_ENDPOINT}/${data?.series_thumbnail}`} className={`thumb-img1`} alt={data?.series_name} />
                                                        {
                                                            data?.series_type == "FREE" &&
                                                            <div style={{ position: "absolute", top: "-3px", right: "-7px" }}>
                                                                <img src={freetag} style={{ width: "70px" }} />
                                                            </div>
                                                        }
                                                    </Link>
                                                    <div className='hover-class1'>
                                                        <div style={{ display: "flex", gap: "8px", alignItems: "center" }}>
                                                            {/* <PlayCircleOutlineIcon style={{ fontSize: "1.6rem", color: "#ba000b" }} /> */}
                                                            <img src={playIcon} style={{ width: "20px" }} />
                                                            <span style={{ fontSize: "1.2rem", color: "white" }}>Watch Now</span>
                                                        </div>
                                                        <div style={{ color: "white", fontSize: "0.95rem" }}>
                                                            {data.series_name}
                                                        </div>

                                                    </div>
                                                </div>
                                            </SwiperSlide>
                                        ))

                                    }

                                </Swiper>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
        </div >
    )
}
