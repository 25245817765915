import React, { useMemo, useEffect } from "react";
import { CircularProgress, Typography } from "@mui/material";
import styles from "./form.module.css";
import "./form.css"
import { useState } from "react";
import axios from "axios";
import OtpInput from 'react-otp-input';
import { login, forgot_password_verify_otp, resend_otp } from "../../../../actions/HomeActions"
import { useDispatch, useSelector } from "react-redux";
import { Col, Form, Row } from 'react-bootstrap'
import { useHistory } from "react-router-dom";
import { toast } from 'react-toastify';
import AlertMessage from '../../common/AlertMessage';
import { FORGOT_PASSWORD_MESSAGE } from "../../../../Actiontype/ActionType";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import { GoogleOAuthProvider } from '@react-oauth/google';
import { GoogleLogin } from '@react-oauth/google';
import subscriptionBG from '../../../../assets/regal_images/Women.png'
import LoadingGIF from '../../../../assets/regal_images/regal_logo.png'
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { auth } from "../firebase.config";
import { RecaptchaVerifier, signInWithPhoneNumber } from 'firebase/auth'
import { bindActionCreators } from "redux";
import * as HomeAction from '../../../../actions/HomeActions'
import { jwtDecode } from "jwt-decode";
import { GUJJU_API } from "../../../../constant/ApiList";
import bgImg from '../../../../assets/regal_images/Tygon1.png'

const SignInForm = () => {
  const history = useHistory()
  const location = useLocation()
  const dispatch = useDispatch()
  const [form, setForm] = useState({})
  const [seconds, setSeconds] = useState(0);
  const resendOTPDuration = 60
  const [countryCode, setCountryCode] = useState("au");
  const [otpsectionvisible, setOtpSectionVisible] = useState(false)
  const [phoneNumber, setPhoneNumber] = useState(null);
  const { VerifyOTP, loginwithGmail } = bindActionCreators(HomeAction, dispatch)
  const { loginwithOTP, getUserStatus, getCityFromIp } = HomeAction
  const [loader, setLoader] = useState(false)
  const [verifyLoader, setVerifyLoader] = useState(false)
  const [email, setEmail] = useState("")
  const [dialCode, setDialCode] = useState("")
  const [country, setCountry] = useState({})
  useEffect(() => {
    if (seconds <= 0) {
      return;
    }
    const timer = setInterval(() => {
      setSeconds((prevSeconds) => prevSeconds - 1);
    }, 1000);

    // Clean up the timer
    return () => clearInterval(timer);
  }, [seconds]);

  const getData = async () => {
    const res = await axios.get("https://api.ipify.org/?format=json");
    setForm({ ...form, ip_address: res.data.ip });
    return res.data.ip

  };
  useEffect(() => {
    (
      (async () => {
        const ipAddress = await getData();
        if (ipAddress) {
          const resData = await getCityFromIp({ ip_address: ipAddress })
          console.log(resData);
          setCountry(resData)
        }

      })()
    )
  }, []);

  useEffect(() => {
    const fetchIP = async () => {
      try {
        const response = await axios.get("https://api.ipify.org/?format=json");
        const country_code = await axios.post(`${GUJJU_API}/country_select/`, { ip_address: response?.data?.ip })
        setDialCode(country_code)
        const ipAddress = response?.data?.ip;

        // Fetch country code based on IP address
        const countryResponse = await axios.get(`https://ipapi.co/${ipAddress}/country`);
        const country = countryResponse?.data?.toLowerCase();

        setCountryCode(country);
      } catch (error) {
        console.error("Error fetching IP:", error);
      }
    };

    fetchIP();
  }, []);




  const [open, setOpen] = useState(false)

  const [passwordType, setPasswordType] = useState("Password")
  const message = useSelector((state) => state.home.login_message);
  const verification_message = useSelector((state) => state.home.forgot_password_message);

  useMemo(() => {
    if (verification_message?.statuscode == 200) {
      if (form?.password) { dispatch(login(form, history)) }
    }


  }, [verification_message])
  useEffect(() => {
    if (location?.state?.showPasswordUpdateMessage)
      toast.success(location?.state?.showPasswordUpdateMessage)
    dispatch({ type: FORGOT_PASSWORD_MESSAGE, payload: null })
  }, [location?.state?.showPasswordUpdateMessage])
  const handleClose = () => {


    setOpen(false);
  };


  const onSignup = async () => {
    setLoader(true)
    console.log("first", form)
    const resData = await loginwithOTP(form)
    console.log(resData)
    if (resData?.statuscode == 200) {
      setOtpSectionVisible(true)
      setLoader(false)

    }
    else {
      setLoader(false)
    }
  }

  const onOTPVerify = async () => {
    setVerifyLoader(true)
    const resData = await VerifyOTP(form)
    console.log(resData)
    if (resData?.statuscode == 200) {
      // if (resData?.new_user == "true") {
      //   setVerifyLoader(false)
      //   history.push("/profile", { firsttime: true, mobileNumber: form?.mobileNumber, user_id: resData?.user_data?.id })
      //   setVerifyLoader(false)

      // }
      // else {
      setVerifyLoader(false)

      history.push("/")
      // }
    } else {
      setVerifyLoader(false)
      toast.error(resData?.msg)
    }


  }

  console.log(form)
  const handleGoogleLogin = async (data) => {
    let GoogleData = jwtDecode(data?.credential)
    const ip = await axios.get("https://api.ipify.org/?format=json");

    if (GoogleData?.email_verified) {
      const res = await getUserStatus({ email: GoogleData?.email })
      if (res?.statuscode == 200 && res?.is_user_active) {
        const resData = await loginwithGmail({ ip_address: ip?.data?.ip, email: GoogleData?.email })
        if (resData?.statuscode == 200) {
          if (resData?.new_user == "true") {
            history.push("/profile", { firsttime: true, email: GoogleData?.email, user_id: resData?.user_data?.id })
          }
          else {
            history.push("/profile_selection")
          }

        }
        else {
          toast.error("Something went wrong")
        }
      }
      else {
        toast.error("Your account has been deactivated by the admin")
      }

    }
    else {
      toast.error("Something went wrong")
    }

  }

  return (
    <div className="site-content-contain " style={{backgroundImage:`url(${bgImg})`,backgroundSize:"cover"}}>
      <AlertMessage />

      <div id='recaptcha-container'></div>

      <div id="content" className="site-content">

        <div id="primary" className="content-area">
          <main id="main" className="" style={{ overflow: "hidden" }}>
            <Row>

              <Col lg="5" sm="12" className="responsive-login">
                <div className='marquee' style={{ height: "100vh" }}>
                  {/* <img src={subscriptionBG} width={"100%"} /> */}
                  {/* <img src={subscriptionBG} width={"100%"} /> */}

                </div>
                <div className='overlay-shadow' style={{ height: "100vh" }}>
                  <img src={LoadingGIF} width={"400px"} />
                  {/* <p className='mt-3'>Sign in now and start streaming</p> */}
                </div>
              </Col>
              <Col lg="7" sm="12">
                <div className="sign-in-page-div-1">
                  <div style={{ display: otpsectionvisible ? "none" : "flex" }} className="sign-in-page-div">
                    <h2>Login to Tygon Fantasy</h2>
                    <span>Experience entertainment like never before with Tygon Fantasy, every login opens the door to a universe of excitement.</span>
                    <div className="signin-page-input-and-button">
               
                        <div>
                          <div className="sign-in-page-mobile-input" style={{ display: "flex", justifyContent: "center" }}>
                            <div style={{ display: "flex", justifyContent: "start" }}>
                              <p style={{ fontSize: "14px" }}> Enter Mobile Number</p>
                            </div>
                            <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>

                              <PhoneInput
                                labelId="demo-simple-select-label"
                                country={'in'}
                                required={true}
                                // value={form?.phoneNumber}

                                onChange={(phone, countryCode) => {
                                  setForm({
                                    ...form,
                                    mobileNumber:
                                      // form?.countryCode == "+" + countryCode.dialCode

                                      (countryCode.dialCode == "91" ? (phone[2] >= 6 ? phone.substring(2) : countryCode.dialCode)
                                        : phone.substring(2)),
                                    countryCode: "+" + countryCode.dialCode,
                                  });
                                  console.log(form)
                                }}
                                isValid={(value, country) => {
                                  if (country.dialCode == "91" && value[2] < 6) {
                                    return 'Invalid Number'
                                  } else {
                                    return true;
                                  }
                                }}
                                dropdownClass={styles.phoneInputDropDown}
                                highlightClass={styles.highlight}
                                buttonClass={styles.phoneInputButton}
                                inputClass={styles.phoneInput}
                              />

                              <button disabled={loader} className="ml-2" onClick={onSignup} >{
                                !loader && <span>Submit</span>}
                                {
                                  loader &&
                                  <div className="ml-2">
                                    <CircularProgress size={25} style={{ color: "black" }} />
                                  </div>

                                }
                              </button>




                            </div>
                          </div>

                          {/* <div style={{ textAlign: "center" }}>
                            or
                          </div> */}
                        </div>
                      

                      {/* <div style={{ display: "flex", justifyContent: "center" }}>
                        <GoogleOAuthProvider clientId="846756764119-91p1ls3cem6e7jtc6t3cpmgb54vd1751.apps.googleusercontent.com">
                          <GoogleLogin
                            auto_select={false}
                            // width={200}
                            theme="filled_black"
                            onSuccess={credentialResponse => {
                              // console.log(credentialResponse)
                              handleGoogleLogin(credentialResponse)
                            }}
                            onError={() => {
                              console.log('Login Failed')
                            }}
                          />
                        </GoogleOAuthProvider>
                      </div> */}
                      <div className="mt-3" onClick={() => history.goBack()} style={{ display: "flex", justifyContent: "left", cursor: "pointer", alignItems: "center" }}><i className="fa fa-arrow-left mr-2" aria-hidden="true"></i>
                        Back</div>

                    </div>
                  </div>

                  <div style={{ display: otpsectionvisible ? "flex" : "none", height: "100%", width: "100%", alignItems: "center", justifyContent: "center" }} >
                    <div className="sign-in-page-div-2">
                      <div onClick={() => setOtpSectionVisible(false)} style={{ cursor: "pointer", fontSize: "1.1rem", display: "flex", alignItems: "center", gap: "5px" }}>
                        <ArrowBackIcon />
                        <span >
                          Back
                        </span>
                      </div>
                      <span>Enter OTP sent to : {form?.countryCode + " " + form?.mobileNumber?.substring(0, 6).replace(/./g, '*') + form?.mobileNumber?.substring(6)}</span>
                      <OtpInput
                        containerStyle={{ display: "flex", justifyContent: "start" }}
                        value={form?.otp}
                        onChange={(e) => (setForm({ ...form, otp: e }))}
                        numInputs={6}
                        inputStyle={{ width: "2.8em", height: "2.8em", fontSize: "1em", padding: "0.04em", borderRadius: ".5rem", borderWidth: "2px", background: "transparent" }}
                        inputType='tel'
                        renderSeparator={<span> &nbsp;&nbsp; </span>}
                        renderInput={(props) => <input {...props} />}
                      />


                      <button disabled={verifyLoader} onClick={onOTPVerify}>{
                        !verifyLoader && <span>Verify</span>}
                        {
                          verifyLoader &&
                          <CircularProgress size={25} style={{ color: "black" }} />

                        }
                      </button>




                    </div>
                  </div>
                </div>

              </Col>


            </Row>
          </main>
        </div>
      </div>
    </div >
  );
};

export default SignInForm;
