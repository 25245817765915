import React, { useEffect, useState, useMemo } from 'react'
import VideoJS from './player'
import videojs from 'video.js';
import { useLocation } from 'react-router-dom';
import 'video.js/dist/video-js.css';
import { useHistory } from 'react-router-dom';
import "videojs-markers"
import { useDispatch, useSelector } from 'react-redux';
// import { advertise_seen_count_change } from '../actions/MovieAction';
import { bindActionCreators } from 'redux';
import * as MovieAction from '../actions/MovieAction'
import left_arrow from "../assets/regal_images/left-arrow.png"
import logo from '../assets/regal_images/regal_logo.png'

import image1 from '../assets/regal_images/qr_code.jpg'
// import image2 from '../assets/regal_images/qr-code1.png'
// import image3 from '../assets/regal_images/qr-code2.png'


import axios from "axios";
import { Box, Typography } from '@mui/material';
import Modal from '@mui/material/Modal';
import { RUNNING_STATUS } from '../Actiontype/ActionType';
import * as HomeAction from '../actions/HomeActions';
import { IMAGE_ENDPOINT } from '../constant/ApiList';
import { ROUTES } from '../constant/routes';
// import scratchcardimage from '../assets/regal_images/scratchcard.png'
import scratchcardimage from '../assets/regal_images/scratchcard1.png'
import { NavLink } from 'react-router-dom'
import scratchgif from '../assets/regal_images/scratchgif.gif'
import VideoFile123 from './VideoFile123';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  maxHeight: "70vh",
  width: 400,
  bgcolor: 'rgb(25,25,25)',
  border: '2px solid rgb(255,255,255,0.5)',
  boxShadow: 24,
  borderRadius: "10px",
  p: 4,
};

require("@silvermine/videojs-quality-selector")(videojs);

export default function VideoFile() {



  const playerRef = React.useRef(null);
  const history = useHistory()
  const location = useLocation()
  const dispatch = useDispatch()
  const [movieData, setMovieData] = useState(location?.state?.movie)
  const [adsFlag, setAdsFlag] = useState(location?.state?.movie?.advertise?.map((ele) => true) || [])
  const isLoggedIn = JSON.parse(sessionStorage.getItem('isLoggedIn'))
  const user_id = JSON.parse(sessionStorage.getItem('user'))?.id
  const profile_id = JSON.parse(sessionStorage.getItem('profile'))?.id
  const { addContinueWatching } = bindActionCreators(MovieAction, dispatch)
  const { change_running_status, getMovieDetails } = bindActionCreators(HomeAction, dispatch)
  const { live_movie_view_count, advertise_seen_count_change } = MovieAction
  const [remainingMin, setRemainingMin] = useState(false)
  let index = 0
  const running_status = useSelector((state) => state.home.running_status)
  const [popupOpen, setPopupOpen] = useState(false)
  const [ip, setIp] = useState("")
  const [selectedLink, setSelectedLink] = useState("")
  const [open, Setopen] = useState(false)
  const { getqrdetail } = bindActionCreators(MovieAction, dispatch);
  const { qrredeemhistory } = bindActionCreators(MovieAction, dispatch);
  const [isChangedLanguage, setIsChangedLanguage] = useState(false);
  const [PlayerTime, setPlayerTime] = useState('');


  // const data = useSelector((state)=> state)

  function timeToSeconds(timeString) {
    // Split the time string by colon
    const parts = timeString.split(':');

    // Parse hours, minutes, and seconds as integers
    const hours = parseInt(parts[0], 10);
    const minutes = parseInt(parts[1], 10);
    const seconds = parseInt(parts[2], 10);

    // Calculate total seconds
    return hours * 3600 + minutes * 60 + seconds;
  }

  function secondsToHms(seconds) {
    // Calculate hours, minutes, and seconds
    const hrs = Math.floor(seconds / 3600);
    const mins = Math.floor((seconds % 3600) / 60);
    const secs = Math.floor(seconds % 60);

    // Add leading zeros to single-digit hours, minutes, and seconds
    const formattedHrs = hrs.toString().padStart(2, '0');
    const formattedMins = mins.toString().padStart(2, '0');
    const formattedSecs = secs.toString().padStart(2, '0');

    // Return formatted string in hh:mm:ss
    return `${formattedHrs}:${formattedMins}:${formattedSecs}`;
  }


  const handleClose = () => {
    Setopen(false);
    history.push(ROUTES.moviedetails + "/" + location?.state?.movie?.Data?.slug)
    // dispatch({ type: RUNNING_STATUS, payload: null })
  }


  useEffect(() => {
    (async () => {
      // const resData = await getqrdetail()
      // setQrdata(resData?.data?.data)
    })();
  }, [])







  useEffect(() => {
    (async () => {
      if (location?.state) {
        setSelectedLink(location?.state?.movie?.movie_links)
        setMovieData(location?.state?.movie)

        const res = await axios.get("https://api.ipify.org/?format=json");
        if (location?.state?.movie?.is_subscription_expiry !== true || location?.state?.movie?.Data?.movie_access == "FREE" || location?.state?.movie?.Data?.movie_access == "TVOD") {
          const result = await change_running_status({
            user: user_id,
            login_type: "Web",
            running_status: "Running",
            ip_address: res.data.ip,
            access_type: location?.state?.movie?.Data?.movie_access,
            movie_id: location?.state?.movie?.Data?.id,
            episode_id: "",
            content_type: location?.state?.movie?.category_type
          })
        }

        // dispatch(change_running_status({ user: user_id, login_type: "Web", running_status: "Running", ip_address: res.data.ip }))
        setIp(res.data.ip)

      }
    })()

  }, [location?.state])

  useMemo(async () => {
    // setAdsFlag(movieData?.advertise?.map((ele) => true))
    if (location?.state?.movie?.is_subscription_expiry !== true || location?.state?.movie?.Data?.movie_access == "FREE" || location?.state?.movie?.Data?.movie_access == "TVOD") {
      let userObj = {
        movie: location?.state?.movie?.Data?.id,
        status: "Running"
      }
      const send_status = await live_movie_view_count(userObj)
    }


  }, [location?.state?.movie?.Data?.id])

  useMemo(async () => {
    setAdsFlag(location?.state?.movie?.advertise?.map((ele) => true) || [])


  }, [movieData])


  const videoJsOptions = {
    autoplay: true,
    loop: false,
    controls: true,
    responsive: true,
    fluid: true,
    muted: false,
    title: movieData?.Data?.movie_name,
    textTrackSettings: false,
    // playbackRates: [0.5, 1, 1.5, 2],
    userActions: { hotkeys: true },
    controlBar: {
      qualitySelector: true,
      fullscreenToggle: true,
      volumePanel: { inline: false },
      pictureInPictureToggle: false,
      skipButtons: {
        forward: 10,
        backward: 10
      }

    },


    tracks: movieData?.subtitle?.map((ele) => ({ src: ele?.subtitle_file, kind: 'subtitles', srclang: 'en', label: ele?.subtitle_language })),
    audio: movieData?.audio?.map((ele) => ({
      id: ele?.id,
      kind: 'alternative',
      label: ele?.audio_language,
      language: ele?.audio_language,
      audio: ele?.audio_file
    })),

    // tracks: [{ src: '', kind: 'captions', srclang: 'en', label: 'English' }],
    sources: [
      {
        // src: `https://video.gumlet.io/661a27f82f2afe335b81177a/66d15ddc44f5c916c123b11e/main.m3u8`,
        src: movieData?.Data?.movie_link,
        type: 'application/x-mpegURL',
      }
    ]

  };
  const handleAdvertiseSeen = (index) => {
    dispatch(advertise_seen_count_change({ advertise: location?.state?.movie?.advertise[index]?.advertise?.id, category_type: location?.state?.movie?.Data?.category_type, movie_id: location?.state?.movie?.Data?.id }))

  }


  const handlePlayerReady = (player) => {
    var currentTime = 0;
    playerRef.current = player;

    if (location?.state?.resume) {
      player.pause()
      const [hours, minutes, seconds] = location?.state?.resume?.split(':');
      const totalSeconds = (+hours) * 60 * 60 + (+minutes) * 60 + (+seconds);

      setTimeout(() => {
        currentTime = totalSeconds
        player?.currentTime(totalSeconds)
        playerRef.current.currentTime(totalSeconds)
        player.play()

      }, 1500);
    }



    player.controlBar.addChild('MenuButton', {
      className: 'audio_subtitle_resolution_controls',
      clickHandler: function () {
        if (!player.paused()) {
          player.play()
        }

      }

    })



    player?.addChild("QualitySelector")
    //  player.addChild("PlaybackRateMenuButton")
    player.addChild("fullscreenToggle")
    player.controlBar.removeChild("QualitySelector")
    //  player.controlBar.removeChild("PlaybackRateMenuButton")
    player.controlBar.removeChild("fullscreenToggle")
    player.titleBar.removeClass('vjs-hidden')
    // player.titleBar.addChild('button', {
    //   controlText: `${movieData?.Data?.movie_name}`,
    //   className: 'vjs-visible-text',
    //   position: 2,
    // });
    player.markers({
      markerStyle: {
        'position': 'absolute',
        'height': '4px',
        'width': '4px',
        'top': "0",
        'background-color': 'yellow'
      },
      markers: (adsFlag || [])?.map((ele, index) => {
        var time = movieData.advertise[index]?.time?.split(":")
        time = (Number(time[0]) * 3600) + (Number(time[1]) * 60) + (Number(time[2]))
        return { time: time }
      }

      )

    })

    player.addChild('button', {
      className: 'ads-icon'
    });
    player.addChild('button', {
      className: 'go-back',
      clickHandler: function (event) {
        history.push(`${ROUTES.moviedetails}/${movieData?.Data?.slug}`)
      }
    });

    const goBackButton = player.addChild('button', {
      className: 'free-preview-class',
      clickHandler: function (event) {
        history.push(`${ROUTES.moviedetails}/${movieData?.Data?.slug}`);
      }
    });




    player.on("userinactive", function () {
      document.getElementsByClassName("vjs-fullscreen-control")[0].style.display = "none"
      document.getElementsByClassName(" go-back")[0].style.display = "none"



    })
    player.on("useractive", function () {
      document.getElementsByClassName("vjs-fullscreen-control")[0].style.display = "block"
      document.getElementsByClassName(" go-back")[0].style.display = "block"
    })

    player.ads();

    const [hours, minutes, seconds] = movieData.Data?.skip_start?.split(':') || [0, 0, 0];
    const totalSeconds = (+hours) * 60 * 60 + (+minutes) * 60 + (+seconds);

    const [hours1, minutes1, seconds1] = movieData.Data?.skip_end?.split(':') || [0, 0, 0];
    const endSkip = (+hours1) * 60 * 60 + (+minutes1) * 60 + (+seconds1);
    let skipFlag = true

    const [hours2, minutes2, seconds2] = movieData?.Data?.movie_duration.split(':') || [0, 0, 0];
    const episodeDuration = (+hours2) * 60 * 60 + (+minutes2) * 60 + (+seconds2);



    player.on('timeupdate', function () {

      const user_id = JSON.parse(sessionStorage.getItem('user'))?.id

      const currentTime = Math.floor(player.currentTime());

      console.log("freepreview", location)
      if (location?.state?.isFreePreview) {
        const totalSeconds = timeToSeconds(movieData?.Data?.free_preview_duration);
        if (currentTime >= totalSeconds) {
          player.pause();
          setTimeout(() => {
            history.replace(ROUTES.moviedetails + "/" + movieData?.Data?.slug, { isWatched: true })
          }, 1000)

        }
        if (totalSeconds > currentTime) {
          const goBackButtonEl = goBackButton.el();
          goBackButtonEl.innerHTML = `Free Preview :<span class="time ml-1">${secondsToHms(totalSeconds - currentTime)}</span>`;
          goBackButtonEl.style.fontSize = "13px";
          // goBackButtonEl.style.color = "red";
        }


      }

      // if (location?.state?.movie?.is_subscription_expiry && location?.state?.movie?.Data?.movie_access == "SVOD") {
      //   const [hours, minutes, seconds] = location?.state?.movie?.free_preview_time?.split(':') || [0, 0, 0];
      //   const totalSeconds = (+hours) * 60 * 60 + (+minutes) * 60 + (+seconds);
      //   player.controlBar.progressControl.addClass("vjs-hidden")

      //   player.controlBar.skipForward.addClass("vjs-hidden")
      //   player.controlBar.skipBackward.addClass("vjs-hidden")

      //   const remaining = totalSeconds - player?.currentTime()

      //   const r_minutes = Math.floor(remaining / 60);
      //   const r_seconds = Math.floor(remaining % 60);

      //   // console.log("here this is", location?.state?.movie?.free_preview_time);
      //   if (player?.currentTime() >= totalSeconds) {
      //     setRemainingMin(true)
      //     Setopen(true)
      //     player.pause();
      //     // alert("please subscribe")
      //     // history.push(ROUTES.moviedetails + "/" + location?.state?.movie?.Data?.slug)
      //   }
      // }



      if (parseInt(player?.currentTime()) >= totalSeconds && parseInt(player?.currentTime()) <= endSkip && skipFlag) {
        player.addChild('button', {
          className: 'skip-intro',
          id: 'skip-intoduction',
          clickHandler: function (event) {
            player?.currentTime(endSkip)
          }
        })
        skipFlag = false
      }
      else if ((parseInt(player?.currentTime()) < totalSeconds || parseInt(player?.currentTime()) > endSkip) && !skipFlag) {
        // const skipButton = player.getChild('button.skip-intro');
        const skipButton = document.getElementsByClassName('skip-intro')[0];

        if (skipButton) {
          skipButton.remove();
          skipFlag = true
        }


      }


      else {
        const nextMovie = document.getElementsByClassName('recomendedMovies-player')[0];

        if (nextMovie) {
          nextMovie.remove();
        }
      }

      adsFlag?.map((ele, index) => {
        var time = movieData.advertise[index]?.time?.split(":")
        time = (Number(time[0]) * 3600) + (Number(time[1]) * 60) + (Number(time[2]))
        if (Math.round(player?.currentTime()) >= time && ele) {

          player.trigger('adsready');
          player.controlBar.progressControl.disable()

          player.controlBar.skipForward.addClass("vjs-hidden")
          player.controlBar.skipBackward.addClass("vjs-hidden")
          document.getElementsByClassName("vjs-play-progress")[0].style.backgroundColor = "yellow"
          document.getElementsByClassName("ads-icon")[0].style.display = "block"
          var tiles = document.getElementsByClassName("vjs-marker")
          for (var i = 0; i < tiles.length; i++) {
            tiles[i].style.backgroundColor = "transparent";

          }
        }
      })

    });

    player.on('readyforpreroll', function () {
      player.ads.startLinearAdMode();
      // play your linear ad content
      // in this example, we use a static mp4
      for (let index = 0; index < adsFlag.length; index++) {
        const element = adsFlag[index];
        if (element) {
          player.src(movieData.advertise[index]?.advertise?.advertise_url);
          handleAdvertiseSeen(index)

          break
        }

      }

      player.options.controls = false;
      // send event when ad is playing to remove loading spinner
      player.on('adplaying', function () {
        player.trigger('ads-ad-started');
      });

      // resume content when all your linear ads have finished
      player.on('adended', function () {
        player.ads.endLinearAdMode();
        for (let index = 0; index < adsFlag.length; index++) {
          const element = adsFlag[index];
          if (element) {
            const temp = adsFlag
            temp[index] = false
            setAdsFlag([...temp])
            break
          }

        }
        player.controlBar.progressControl.enable()
        player.controlBar.skipForward.removeClass("vjs-hidden")
        player.controlBar.skipBackward.removeClass("vjs-hidden")
        document.getElementsByClassName("vjs-play-progress")[0].style.backgroundColor = "red"
        document.getElementsByClassName("ads-icon")[0].style.display = "none"
        // player.src(videoJsOptions.sources)

        var tiles = document.getElementsByClassName("vjs-marker")
        for (var i = 0; i < tiles.length; i++) {
          tiles[i].style.backgroundColor = "yellow";

        }

      });
    });
    // You can handle player events here, for example:
    player.on('waiting', () => {
      videojs.log('player is waiting');
    });

    window.addEventListener('beforeunload', async (event) => {
      event.preventDefault();
      // if (isLoggedIn && profile_id && user_id && playerRef && running_status?.statuscode == 200) {
      if (isLoggedIn && user_id && playerRef) {

        let time = new Date(parseInt(playerRef?.current?.currentTime()) * 1000)
          .toISOString()
          .slice(11, 19);
        let userObj = {
          user: user_id,
          profile: profile_id,
          select_type: movieData?.Data?.category_type,
          movie: movieData?.Data?.id,
          serie: "",
          episode: "",
          last_watching_time: time
        }
        event.returnValue = await addContinueWatching(userObj, location?.state?.isFreePreview ? false : true);

      }
    });

    player.on('dispose', async () => {
      // let currentTime = player.currentTime();
      let time = new Date(parseInt(player?.currentTime()) * 1000)
        .toISOString()
        .slice(11, 19);
      videojs.log('player will dispose', time);
      if (isLoggedIn && user_id) {
        let userObj = {
          user: user_id,
          profile: profile_id,
          select_type: movieData?.Data?.category_type,
          movie: movieData?.Data?.id,
          serie: "",
          episode: "",
          last_watching_time: time
        }

        const res = await axios.get("https://api.ipify.org/?format=json");
        // if (location?.state?.movie?.is_subscription_expiry !== true || location?.state?.movie?.Data?.movie_access == "FREE" || location?.state?.movie?.Data?.movie_access == "TVOD") {
        const resData = await addContinueWatching(userObj, location?.state?.isFreePreview ? false : true)

        // }
        // else {
        //   const resData = await addContinueWatching(userObj, false)

        // }



      }
    });
    player.on("seeking", () => {

      currentTime = player?.currentTime()
    })



    player.on('canplay', () => {
      if (player?.currentTime() != currentTime)
        player?.currentTime(currentTime)

    })
    player.on("loadeddata", function () {

      setTimeout(() => {
        let QualitySelector = document.getElementsByClassName("vjs-quality-selector")[0]

        let AudioButton = document.getElementsByClassName("vjs-audio-button")[0]

        QualitySelector.classList.remove("vjs-hidden")
        QualitySelector.getElementsByClassName("vjs-menu")[0].classList.remove("vjs-hidden")
        QualitySelector.getElementsByClassName("vjs-menu")[0].classList.add("always-open")

        AudioButton.classList.remove("vjs-hidden")
        AudioButton.getElementsByClassName("vjs-menu")[0].classList.remove("vjs-hidden")
        AudioButton.getElementsByClassName("vjs-menu")[0].classList.add("always-open")
        AudioButton.getElementsByClassName("vjs-menu")[0].classList.add("margin-custom-audio")

        try {
          const li1 = document.createElement("li");
          li1.setAttribute("class", " vjs-menu-item vjs-alternative-menu-item custom-li");
          li1.appendChild(QualitySelector);

          document.getElementsByClassName("audio_subtitle_resolution_controls")[0].getElementsByTagName("ul")[0].appendChild(li1)
        }
        catch (error) {
          console.log(error, "li1")
        }
        try {
          const li2 = document.createElement("li");
          li2.setAttribute("class", " vjs-menu-item vjs-alternative-menu-item custom-li li-second");
          li2.appendChild(AudioButton);
          document.getElementsByClassName("audio_subtitle_resolution_controls")[0].getElementsByTagName("ul")[0].appendChild(li2)
        }
        catch (error) {
          console.log(error, "li2")
        }

        let SubsCapsButton = document.getElementsByClassName("vjs-subs-caps-button")[0]
        SubsCapsButton.classList.remove("vjs-hidden")
        SubsCapsButton.getElementsByClassName("vjs-menu")[0].classList.remove("vjs-hidden")
        SubsCapsButton.getElementsByClassName("vjs-menu")[0].classList.add("always-open")
        try {
          const li3 = document.createElement("li");
          li3.setAttribute("class", " vjs-menu-item vjs-alternative-menu-item custom-li");
          li3.appendChild(SubsCapsButton);

          document.getElementsByClassName("audio_subtitle_resolution_controls")[0].getElementsByTagName("ul")[0].appendChild(li3)
        }
        catch (error) {
          console.log(error, "li3")
        }

        var qualitySelectorItems = document.getElementsByClassName("audio_subtitle_resolution_controls")[0].getElementsByTagName("ul");
        for (var i = 0; i < qualitySelectorItems.length; i++) {

          qualitySelectorItems[i].addEventListener("click", function (event) {
            event.stopPropagation()
            document.getElementsByClassName("audio_subtitle_resolution_controls")[0].getElementsByTagName("button")[0].click()

          });

        }
        var lis = document.getElementsByClassName("custom-li")
        for (var i = 0; i < lis.length; i++) {

          if (lis[i].children.length <= 0) {

            lis[i].remove()
          }
        }
        var lis = document.getElementsByClassName("li-second")
        for (var i = 0; i < lis.length; i++) {

          if (lis[i].children.length <= 0) {

            lis[i].remove()
          }
        }

      }, 1000)





    })





    player.on("qualityRequested", () => {
      if (player.play()) {
        player.play()
      }
      const temp = document.getElementsByClassName("vjs-menu-item vjs-selected")[3].getElementsByClassName("vjs-menu-item-text")[0].innerText
      player.src(videoJsOptions.sources.find((ele) => ele?.label == temp))
      for (let i = 0; i < videoJsOptions.sources.length; i++) {
        videoJsOptions.sources[i].selected = (videoJsOptions.sources[i]?.label == temp)
      }
      currentTime = player?.currentTime()
      player.load();

    })


  };

  const handlePopupClose = () => {
    setPopupOpen(false);
    history.goBack()
    dispatch({ type: RUNNING_STATUS, payload: null })
  };

  useMemo(() => {
    if (running_status?.statuscode == 406) {
      setPopupOpen(true)



    }
  }, [running_status])


  return (
    <>
      {/* <div id="gif-container"></div> */}
      <Modal
        open={popupOpen}
        onClose={handlePopupClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>

          <div style={{ display: "flex", justifyContent: "center" }}>
            <img src={logo} width={"100px"} />
          </div>
          <hr />

          <div className="login-popup" >
            <Typography id="modal-modal-title" variant="h6" component="h2" sx={{ mt: 2, textAlign: "center" }}>
              {running_status?.message}
            </Typography>
            <div style={{ display: "flex", justifyContent: "space-around" }}>

              <button onClick={handlePopupClose} className={"btn btn-hover iq-button"} style={{ display: "block", margin: "0 auto" }}>
                Close
              </button>
            </div>
          </div>
        </Box>
      </Modal>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >

        <Box sx={style}>

          <div style={{ display: "flex", justifyContent: "center" }}>
            <img src={logo} width={"100px"} />
          </div>
          <hr />

          <div className="login-popup" >
            <Typography id="modal-modal-title" variant="h6" component="h2" sx={{ mt: 2, textAlign: "center", marginBottom: "1rem" }}>
              {/* {running_status?.message} */}
              Pay & watch {movieData?.Data?.currency_symbol} {Number(movieData?.Data?.rental_price_with_ad).toFixed(2)}
            </Typography>
            <div style={{ display: "flex", justifyContent: "space-between" }}>

              <button onClick={() => history.goBack()} className={"btn btn-hover iq-button"} >
                Back
              </button>

              <NavLink to={ROUTES.priceplan}>
                <button
                  className={"btn btn-hover iq-button"}
                  style={{ display: "block", margin: "0 auto" }}
                  onClick={() => history.replace("/price-plan",
                    {
                      transactionType: "TVOD", redirect: ROUTES.moviedetails + '/' + movieData?.Data?.slug,
                      available_for: movieData?.Data?.available_for_days,
                      movieName: movieData?.Data?.movie_name, movie: movieData?.Data?.id,
                      categoryType: "Movie", price: movieData?.Data?.rental_price_with_ad,
                      adfreePrice: movieData?.Data?.rental_price, ads: movieData?.advertise,
                      currency: movieData?.Data?.currency_symbol,
                      slug: movieData?.Data?.slug
                    })}
                >
                  Continue
                </button>
              </NavLink>

            </div>
          </div>
        </Box>
      </Modal>

      {!popupOpen && <div className='video-parent video'>
        <VideoJS playerRef={playerRef} options={videoJsOptions} onReady={handlePlayerReady} />


      </div>}
      {/* <div onClick={() => history.goBack()} className='go-back' ><img src={left_arrow} width={"40px"}/></div> */}

    </>
  )
}


