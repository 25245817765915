import React, { useEffect, useState } from 'react'
import { Container, Col, Row, Nav, Tab, } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { Swiper, SwiperSlide } from 'swiper/react';
import fav4 from '../../../assets/images/favorite/04.png'
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';
import { Box, IconButton, Modal, Typography } from '@mui/material';
import { bindActionCreators } from 'redux';
import { useDispatch } from 'react-redux';
import * as MovieAction from '../../../actions/MovieAction'
import * as HomeActions from '../../../actions/HomeActions'

import { IMAGE_ENDPOINT } from '../../../constant/ApiList';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { ROUTES } from '../../../constant/routes';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import logo from '../../../assets/regal_images/regal_logo.png'
import AlertMessage from '../common/AlertMessage';
import { toast } from 'react-toastify';
import PlayCircleFilledWhiteIcon from '@mui/icons-material/PlayCircleFilledWhite';
import InfoIcon from '@mui/icons-material/Info';
import DeleteIcon from '@mui/icons-material/Delete';
import dots from '../../../assets/regal_images/more.png'
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import axios from 'axios';
import { Base64 } from 'js-base64';
const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'black',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};

const styleforAlert = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    maxHeight: "70vh",
    width: 600,
    bgcolor: 'rgb(25,25,25)',
    border: '2px solid rgb(255,255,255,0.5)',
    boxShadow: 24,
    borderRadius: "10px",
    p: 4,
}


function LinearProgressWithLabel(props) {
    return (
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Box sx={{ width: '100%' }}>
                <LinearProgress variant="determinate" {...props} />
            </Box>

        </Box>
    );
}

export default function ContinueWatching() {
    const ITEM_HEIGHT = 48;

    const user_id = JSON.parse(sessionStorage.getItem('user'))?.id
    const profile_id = JSON.parse(sessionStorage.getItem('profile'))?.id
    const dispatch = useDispatch()
    const { getMovieDetails } = bindActionCreators(HomeActions, dispatch)
    const { getContinueWatchingData } = bindActionCreators(MovieAction, dispatch)
    const [continueWatching, setContinueWatching] = useState([])
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [movieById, setMovieById] = useState("")
    const [openDialoge, setOpenDialoge] = React.useState(false);
    const history = useHistory()
    const [save, setSave] = useState(false)
    const [completedHour, setCompletedHour] = useState(false)
    const isRunningInElectron = navigator.userAgent.toLowerCase().indexOf(' electron/') > -1;

    const open = Boolean(anchorEl);
    const handleClick = (event, index) => {
        setAnchorEl(event.currentTarget);
        setMovieById(continueWatching?.data[index])
    };

    const { deleteContinueWatching } = MovieAction
    const handleRedirect = () => {
        if (movieById?.select_type == "Series") {
            // history.push(ROUTES.seriesDetails, { series: movieById?.episode?.series_name?.id })
            history.push(`${ROUTES.seriesDetails}/${movieById?.series?.slug}`)

            setAnchorEl(null);
        }
        else {
            history.push(`${ROUTES.moviedetails}/${movieById?.movie?.slug}`)
            setAnchorEl(null);
        }


    }
    const handleClose = () => {
        setAnchorEl(null);
        setOpenDialoge(false)
        setCompletedHour(false)
    };


    useEffect(() => {
        (async () => {
            if (user_id) {
                let userObj = {
                    profile_id: profile_id,
                    user_id: user_id
                }
                const resData = await getContinueWatchingData(userObj)
                // setContinueWatching(resData?.data)
                setContinueWatching({ ...resData, data: JSON.parse(Base64.decode(resData.data)) })


            }
        })();
    }, [save, profile_id])

    const openModal = () => {
        setOpenDialoge(true)
    }
    const handleDelete = async () => {
        let userObj = {
            id: movieById?.id
        }
        const resData = await deleteContinueWatching(userObj)
        if (resData?.statuscode == 200) {
            toast.success(resData?.message)
            setOpenDialoge(false)
            setSave(!save)
            setAnchorEl(null);

        }
    }
    const playContinueWatching = async (data) => {
        if (movieById?.select_type == "Series") {
            history.push(ROUTES.episode, { data: movieById?.episode, resume: movieById?.last_watching_time })
        }
        else {
            const res = await axios.get("https://api.ipify.org/?format=json");

            let userObj = {
                user_id: user_id,
                profile_id: profile_id,
                ip_address: res.data.ip

            }
            const resData = await getMovieDetails(movieById?.movie?.slug, userObj)
            console.log("resData",resData)

            if (resData?.statuscode == 200) {
                if (resData?.Data?.movie_access == "TVOD" && resData?.get_status == "completed") {
                    setCompletedHour(true)
                }
                else if (resData?.Data?.movie_access == "TVOD" && resData?.get_status == "false" && resData?.is_rental_movie_expiry == true) {
                    history.push(ROUTES.video, { movie: resData, resume: movieById?.last_watching_time, isFreePreview: true })
                }
                else {
                    if (resData?.is_subscription_expiry && resData?.Data?.movie_access == "SVOD") {
                        history.push(ROUTES.video, { movie: resData, resume: movieById?.last_watching_time, isFreePreview: true })

                    }
                    else {
                        history.push(ROUTES.video, { movie: resData, resume: movieById?.last_watching_time })

                    }


                }
            }

        }

    }

    const handleImageClick = async (data) => {
        if (data?.select_type == "Series") {
            history.push(ROUTES.episode, { data: data?.episode, resume: data?.last_watching_time })
        }
        else {
            const res = await axios.get("https://api.ipify.org/?format=json");

            let userObj = {
                user_id: user_id,
                profile_id: profile_id,
                ip_address: res.data.ip

            }
            const resData = await getMovieDetails(data?.movie?.slug, userObj)
            console.log("resData",resData)
            if (resData?.statuscode == 200) {
                if (resData?.Data?.movie_access == "TVOD" && resData?.get_status == "completed") {
                    setCompletedHour(true)
                }
                else if (resData?.Data?.movie_access == "TVOD" && resData?.get_status == "false" && resData?.is_rental_movie_expiry == true) {
                    history.push(ROUTES.video, { movie: resData, resume: resData?.last_watching_time, isFreePreview: true })
                }
                else {
                    if (resData?.is_subscription_expiry && resData?.Data?.movie_access == "SVOD") {
                        history.push(ROUTES.video, { movie: resData, resume: resData?.last_watching_time, isFreePreview: true })

                    }
                    else {
                        history.push(ROUTES.video, { movie: resData, resume: resData?.last_watching_time })

                    }
                }
            }
        }

    }

    return (
        <>
            {/* <AlertMessage /> */}
            {
                (user_id && continueWatching?.data?.length) ?
                    <div className='main-content'>
                        <section id="iq-favorites">
                            <Container fluid>
                                <Row>
                                    <Col sm="12" className="overflow-hidden">
                                        <div className="d-flex align-items-center justify-content-between">
                                            <h6 className="main-title" style={{ textTransform: "uppercase" }}>Continue Watching</h6>
                                            {/* <Link className="iq-view-all" to="/movie-category">View All</Link> */}
                                        </div>
                                        <div id="favorites-contens">
                                            {/* <div id="prev1" className="swiper-button swiper-button-prev"><i className="fa fa-chevron-left"></i></div>
                                                <div id="next1" className="swiper-button swiper-button-next"><i className="fa fa-chevron-right"></i></div> */}
                                            <Swiper
                                                navigation={{
                                                    prevEl: '#prev1',
                                                    nextEl: '#next1'
                                                }}
                                                breakpoints={{
                                                    320: { slidesPerView: 1 },
                                                    450: { slidesPerView: 2 },
                                                    630: { slidesPerView: 3 },
                                                    1190: { slidesPerView: 5 },
                                                    1400: { slidesPerView: 5 }
                                                }}
                                                loop={false}
                                                slidesPerView={4}
                                                spaceBetween={20}
                                                as="ul"
                                                style={{ marginBottom: "-2rem" }}

                                                className="favorites-slider list-inline  row p-0 ml-0 iq-rtl-direction">
                                                {
                                                    continueWatching?.data?.map((data, index) => (
                                                        <SwiperSlide as="li" key={index} style={{ cursor: "pointer" }}>
                                                            <div className="position-relative">
                                                                <div className="img-box">
                                                                    <img src={`${IMAGE_ENDPOINT + data?.image}`} className="continue-watching" alt="" onClick={() => handleImageClick(data)} />
                                                                </div>
                                                                <div className="block-description-dots">
                                                                    <IconButton
                                                                        aria-label="more"
                                                                        id="long-button"
                                                                        aria-controls={open ? 'long-menu' : undefined}
                                                                        aria-expanded={open ? 'true' : undefined}
                                                                        aria-haspopup="true"
                                                                        onClick={(event) => handleClick(event, index)}
                                                                    >
                                                                        {/* <i className="fa fa-ellipsis-v i-color-menu" aria-hidden="true"></i> */}
                                                                        <img src={dots} alt='menu' style={{ height: "30px", width: "40px" }} />
                                                                    </IconButton>
                                                                    <Menu
                                                                        id="long-menu"
                                                                        MenuListProps={{
                                                                            'aria-labelledby': 'long-button',
                                                                        }}
                                                                        anchorEl={anchorEl}
                                                                        open={open}
                                                                        onClose={handleClose}
                                                                        PaperProps={{
                                                                            style: {
                                                                                maxHeight: ITEM_HEIGHT * 4.5,
                                                                                width: '20ch',
                                                                            },
                                                                        }}
                                                                    >
                                                                        <MenuItem onClick={() => playContinueWatching(data)}>
                                                                            <PlayCircleFilledWhiteIcon />  Resume
                                                                        </MenuItem>
                                                                        <MenuItem onClick={() => handleRedirect()}>
                                                                            <InfoIcon />  Details
                                                                        </MenuItem>
                                                                        <MenuItem onClick={() => openModal()}>
                                                                            <DeleteIcon />  Delete
                                                                        </MenuItem>
                                                                    </Menu>
                                                                </div>
                                                                {/* <div className="block-description mb-3" onClick={() => handleImageClick(data)} style={{ cursor: "pointer" }}>
                                                                <div className="block-description mb-4" onClick={() => handleImageClick(data)} style={{ cursor: "pointer" }}>
                                                                    <i className="fa fa-play" aria-hidden="true"></i>
                                                                </div> */}
                                                            </div>
                                                            <div className='progress-bar'>
                                                                <div className='play-icon mb-2' style={{ display: "flex", alignSelf: "center" }}>

                                                                    <p><i className="fa fa-play" aria-hidden="true"></i>  {data?.last_watching_time}</p>

                                                                </div>
                                                                <div className='progress-line'>
                                                                    <LinearProgressWithLabel value={data?.watching_percentage} />

                                                                </div>
                                                            </div>
                                                            <p className='mt-3' style={{ fontSize: "14px" }}>{(data?.movie?.movie_name) ? data?.movie?.movie_name : (data?.episode?.episode_number)}</p>
                                                            {/* <p className='mt-1'>00:20:00</p> */}
                                                        </SwiperSlide>
                                                    ))
                                                }



                                            </Swiper>
                                        </div>
                                    </Col>
                                </Row>
                            </Container>
                        </section>
                        <Modal
                            open={openDialoge}
                            onClose={handleClose}
                            aria-labelledby="modal-modal-title"
                            aria-describedby="modal-modal-description"
                        >
                            <Box sx={style}>
                                <div style={{ display: "flex", justifyContent: "center" }}>
                                    <img src={logo} alt='regal_logo' style={{ width: "100px" }} />
                                </div>
                                <hr />
                                <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                                    Are you sure you want to delete movie from continue watch?
                                </Typography>
                                <div className='pt-3' style={{ display: "flex", justifyContent: "flex-end" }}>
                                    <button onClick={handleDelete} className="btn btn-hover iq-button">
                                        Yes
                                    </button>
                                    <button onClick={handleClose} className="btn btn-hover iq-button ml-2">
                                        No
                                    </button>
                                </div>
                            </Box>
                        </Modal>

                        <Modal
                            open={completedHour}
                            onClose={(handleClose)}
                            aria-labelledby="modal-modal-title"
                            aria-describedby="modal-modal-description"
                        >

                            <Box sx={styleforAlert}>

                                <div style={{ display: "flex", justifyContent: "center" }}>
                                    <img src={logo} width={"100px"} />
                                </div>
                                <hr />

                                <div className="login-popup" >
                                    <Typography id="modal-modal-title" variant="h6" component="h2" sx={{ mt: 2, textAlign: "center" }}>
                                        You have exceeded 72 hours of movie watching limitation , please rent now to continue                                     </Typography>
                                    <div style={{ display: "flex", justifyContent: "end", marginTop: "1.5rem" }}>
                                        <button onClick={(handleClose)} className={"btn btn-hover ml-3 iq-button"}>
                                            Close
                                        </button>
                                    </div>
                                </div>
                            </Box>
                        </Modal>

                    </div>

                    :
                    <></>
            }

        </>
    )
}
