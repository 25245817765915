import React, { useState, useEffect } from 'react'
import { Col, Row, Container } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import footer1 from '../../../../assets/regal_images/Android.png'
import footer2 from '../../../../assets/regal_images/ios.png'
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { rtlModeAction, getRtlMode } from '../../../../store/mode/rtlmode'
import { ROUTES } from '../../../../constant/routes'
import playstore from '../../../../assets/regal_images/playstore.png'
import logo from '../../../../assets/regal_images/regal_logo.png'
import { useHistory } from 'react-router-dom'


const mapStateToProps = (state) => {
    return {
        rtlMode: getRtlMode(state)
    };


}
const mapDispatchToProps = dispatch => ({
    ...bindActionCreators(
        {
            rtlModeAction
        },
        dispatch
    )
})

const FooterStyle = (props) => {
    const [show, setShow] = useState(false);
    const history = useHistory()


    useEffect(() => {
        const rtlMode = sessionStorage.getItem('rtl-mode');
        if (rtlMode === null) {
            props.rtlModeAction(props.rtlMode)
        }
        else {
            props.rtlModeAction(rtlMode);
        }
    })

    return (
        <>
            <div className={`rtl-box ${show === true ? 'show' : ''}`} >

                <div className="rtl-panel">
                    <ul className="modes">
                        <li className="dir-btn" data-mode="rtl" data-active="false" onClick={() => { props.rtlModeAction('ltl') }} data-value="ltr"><Link to="#">LTR</Link></li>
                        <li className="dir-btn" data-mode="rtl" data-active="true" onClick={() => { props.rtlModeAction('rtl') }} data-value="rtl"><Link to="#">RTL</Link></li>
                    </ul>
                </div>
            </div>
            <footer id="contact" className="footer-one iq-bg-dark">
                <div className="footer-top-x mt-5 mb-5">
                    <Container fluid>
                        <Row className="footer-standard">
                            <Col lg="3">
                                <div className="widget text-left">
                                    <div className="menu-footer-link-1-container" style={{ display: "flex", flexDirection: "column" }}>
                                        <img src={logo} style={{ width: "150px" }} />
                                        <Link to="/terms-of-service" className="mt-3">Terms Of Use</Link>
                                        <Link to="/privacy-policy" className="mt-3">Privacy-Policy</Link>
                                        <Link to="/refund-policy" className="mt-3">Refund-Policy</Link>
                                        <Link to="/about-us" className="mt-3">About Us</Link>
                                    </div>
                                </div>

                            </Col>
                            <Col lg="3">
                                <h4>Services</h4>
                                <div className="menu-footer-link-1-container" style={{ display: "flex", flexDirection: "column" }}>
                                    <Link to="/movies" className="mt-3">Movies</Link>
                                    <Link to="/series" className="mt-3">Web Series</Link>
                                    <Link to="/my_account" className="mt-3">My account</Link>
                                    <Link to="/upcoming" className="mt-3">Upcoming</Link>
                                    <Link to="/complaint" className="mt-3">Help & Support</Link>
                                </div>
                            </Col>
                            <Col lg="3">

                                <div className="widget text-left">
                                    <div className="mt-0">

                                        {/* <p><>© 2024 Tygon Fantasy. Tygon Fantasy does not claim ownership of all images and content, including movies and shows on this platform, which are shared solely for entertainment purposes. Created and developed by <span style={{ color: "#ba000b" }}>Tygon Fantasy.</span></></p> */}

                                        <h6 className="footer-link-title">
                                            Follow Us :
                                        </h6>
                                        <ul className="info-share">
                                            <a href='https://www.facebook.com/profile.php?id=61568479224466' target='_blank'>
                                                <li><i className="fa fa-facebook"></i></li>
                                            </a>
                                            {/* <li><Link to="#" ><i className="fa fa-twitter"></i></Link></li> */}
                                            <a href='https://www.instagram.com/tygon_series/' target='_blank'>
                                                <li><i className="fa fa-instagram"></i></li>
                                            </a>
                                        </ul>

                                    </div>
                                </div>
                            </Col>

                            <Col lg="3">

                                <div >
                                    <span>Available On</span>
                                    <a href='https://play.google.com/store/apps/details?id=com.xott.entertainment' target='_blank' style={{ display: "flex" }}>
                                        <img style={{ height: "65px", width: "170px" }} src={playstore} alt="" />
                                        {/* <img style={{ height: "65px", width: "170px" }} src={appstore} alt="" /> */}
                                    </a>

                                </div>
                            </Col>


                        </Row>
                    </Container>
                </div>
            </footer>
        </>
    )
}

export default connect(mapStateToProps, mapDispatchToProps)(FooterStyle)